<template>
    <main>
        <SectionIntroTitle 
        sectionName="Traumatología"
        alignCenter="true"
        ppalText="Medicina regenerativa"
        secondText="y traumatología."
        />
        <p class="description col-12 col-lg-10 col-xl-7"></p>
        <WhiteBackground backColor="white">
            <div class="image">
                <picture>
                    <source :srcset=TraumatologyWebp type="image/webp">
                    <source :srcset=TraumatologyJpg type="image/jpeg" />
                    <source :srcset=TraumatologyPng type="image/png">
                    <img class="col-11" :src=TraumatologyWebp alt="Dolor de columna" />
                </picture>
            </div>
            <div class="intro-text">
                <div>
                    <p class="subtitle"><span class="secondText">Procedimientos traumatológicos</span>  y patologías.</p> 
                </div>
                <div class="text">
                    <p class="bolder">
                        Disponemos de alternativas traumatológicas para la atención de diversas patologías como artrosis, condromalacia y lesiones deportivas. 
                    </p>
                    <p>
                        Los procedimientos aplicados por nuestro traumatólogo especialista tienen como propósito evitar y postergar la colocación de prótesis, para el caso de artrosis de rodilla y cadera. Y en caso de otro tipo de lesiones, acelerar la recuperación.
                    </p>
                    <p>
                        Los procedimientos traumatológicos que entregamos a nuestros pacientes, sustentados científicamente, atienden a un conjunto de patologías como: artrosis de rodilla, artrosis de cadera, lesiones de ligamento cruzado anterior, condromalacia, inestabilidad de rodilla, y otros tipo de lesiones musculo-esqueléticas.
                    </p>
                </div>
            </div>
            <picture>
                <source :srcset=CurveUpWebp type="image/webp">
                <source :srcset=CurveUpSvg type="image/svg+xml" />
                <source :srcset=CurveUpPng type="image/png">
                <img class="curve" :src=TraumatologyWebp alt="Curva decorativa" />
            </picture>

            <SectionTreatments :decorationCurve=false :background=true />

            <SectionName text="Patologías tratables" class="d-flex justify-content-center pt-5 pb-2" />
            <section id="carousel-columns" class="col-12 col-xl-11 pb-5">
                <template v-for="item in pathologyItems" :key="item.id">
                    <CarouselColum
                    :id='item.id'
                    :pathology='item.pathology'
                    :description='item.description'
                    :srcWebp='item.srcWebp'
                    :srcPng='item.srcPng'
                    :srcJpg='item.srcJpg'
                    :altImg='item.altImg'
                    :active="item.active"
                    @beforeClick="showBefore(showNumber)"
                    @nextClick="showNext(showNumber)"
                    @cardClick="toggleActive(item.id)"
                    link="/patologias"
                    />
                </template>
            </section>
        </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
import SectionTreatments from '../HomePage/SectionTreatments.vue';
import CarouselColum from '@/components/CarouselColum.vue';
import SectionName from '@/components/SectionName.vue';
import PathologyInfo from './pathologyInfo.js';

import TraumatologyPng from '../../assets/img/photos/png/traumatology.png';
import TraumatologyJpg from '../../assets/img/photos/jpg/traumatology.jpg';
import TraumatologyWebp from '../../assets/img/photos/webp/traumatology.webp';

import CurveUpSvg from '../../assets/img/decoration/curve-up.svg';
import CurveUpPng from '../../assets/img/decoration/curve-up.png';
import CurveUpWebp from '../../assets/img/decoration/curve-up.webp';


export default {
    name: 'TraumatologyPage',
    components: {
        SectionIntroTitle,
        WhiteBackground,
        FooterBar,
        SectionTreatments,
        CarouselColum,
        SectionName,
    },
    props:  {

    },
    data() {
        return {
            TraumatologyPng : TraumatologyPng,
            TraumatologyJpg : TraumatologyJpg,
            TraumatologyWebp : TraumatologyWebp,
            CurveUpSvg : CurveUpSvg,
            CurveUpWebp : CurveUpWebp,
            CurveUpPng : CurveUpPng,
            PathologyInfo : PathologyInfo,
            showNumber: 1,
        }
    },
    computed: {
        pathologyItems() {
            return this.PathologyInfo.slice();
        },
    },
    methods: {
        toggleActive(selectedItem) {
            let sections = Array.from(document.getElementsByClassName("section"));
            sections.forEach(element => {
                element.classList.remove('active');
            });
            sections[selectedItem - 1 ].classList.add('active');
            this.showNumber = selectedItem;
        },
        showBefore(selectedItem) {
            let sections = Array.from(document.getElementsByClassName("section"));
            sections.forEach(element => {
                element.classList.remove('active');
            });
            sections[selectedItem - 1 ].classList.add('active');
            this.showNumber = this.showNumber - 1;
        },
        showNext(selectedItem) {          
            let sections = Array.from(document.getElementsByClassName("section"));
            sections.forEach(element => {
                element.classList.remove('active');
            });
            sections[selectedItem].classList.add('active');
            this.showNumber = this.showNumber + 1;
        },
    },
}
</script>

<style scoped>
.description{
    text-align: center;
    margin: 2rem auto 10rem auto;
    position: relative;
    z-index: 2;
}
.image{
    position: relative;
    top: -7.5rem;
    margin-bottom: -7.5rem;    
}
.image img{
    min-height: 250px;
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
.intro-text{
    padding: 5rem 0;
    position: relative;
    z-index: 2;
}
.subtitle{
    color: var(--light-violet);
    font-size: 40px;
    line-height: 45px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 2rem;
}
.secondText{
    color: var(--dark-violet);
}
.bolder{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.curve{
    position: relative;
    margin-bottom: -580px ;
    top: -580px ;
    max-width: 100%;
}

#carousel-columns{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: auto;    
}

/* RESPONSIVE */
@media (min-width:576px) {
    .subtitle{
        font-size: 50px;
        line-height: 55px;
        max-width: 80%;
    }
    .text{
        padding-left: 20%;
    }
}

@media (min-width:768px) {
    .curve{
        left: 5%;
    }
}

@media (min-width:992px) {
    #carousel-columns{
        flex-direction: row;
    }
}

@media (min-width:1200px) {
    .subtitle{
        font-size: 60px;
        line-height: 62px;
        max-width: 50%;
    }
    .text{
        padding-left: 42%;
        padding-top: 0.5rem;
    }
    .curve{
        max-width: 390px;
        margin-bottom: -600px;
        top: -600px;
    }
}

@media (min-width:1400px) {}
</style>