/* FAQS INFO */

let faqs = [
    {
        idName: 'general',
        section: 'General',        
        questions: [
            {
                id: 1,
                question: "¿Qué es ArtroStem?",
                description: "ArtroStem es una terapia alogénica en base a células madre mesenquimales de tejido de cordón umbilical que se utiliza para afecciones de daño de tejido articular en diferentes patologías traumatológicas. Todo paciente, independiente de su edad, es candidato para el tratamiento."
            },
        ]
    },
    {
        idName: 'steam-cells',
        section: 'Células madre',
        questions: [
            {
                id: 2,
                question: "¿Qué son las células madre mesenquimales y por qué se usan?",
                description: "Las células madre mesenquimales son células encargadas de mantener el equilibrio fisiológico y servir de reservorio celular. Las utilizamos a diario para reemplazar células o tejidos dañados que se han desgastado. Están presentes durante toda nuestra vida, pero con el tiempo las perdemos en cantidad y calidad, por lo cual resulta útil administrar células de alto potencial. Su uso terapéutico tiene relación a sus propiedades anti- inflamatorias y de favorecer la regeneración y reparación de tejidos dañados."
            },
            {
                id: 3,
                question: "¿Para qué enfermedades traumatológicas están indicadas las células madre?",
                description: `La terapia celular está indicada principalmente en pacientes que no han respondido a otras alternativas de tratamientos convencionales o que deseen un tratamiento más eficaz. En Novocel las utilizamos para: \n
                    • Rodilla: artrosis, condromalacia, lesión osteocondral, meniscopatía. \n
                    • Cadera: artrosis, necrosis avascular. \n
                    Todo tratamiento está sujeto a evaluación médica.`
            },
            {
                id: 4,
                question: "¿Cómo actúan las células madre en artrosis?",
                description: "Las células madre mesenquimales ejercen un efecto antiinflamatorio e inmunomodulador (regulan la respuesta del sistema inmunológico) mediante la producción de moléculas bioactivas y factores de crecimiento que promueven la protección y reparación de los tejidos afectados en la articulación, logrando una disminución del dolor, inflamación y rigidez a largo plazo y, la disminución e incluso detención del curso de la patología por disminuir la degradación del cartílago articular."
            },
            {
                id: 5,
                question: "¿Se usan células del mismo paciente?",
                description: "No. Trabajamos con terapia alogénica, es decir, las células provienen de un “donante universal”, por lo que es compatible con cualquier persona, permite asegurar homogeneidad y su efecto terapéutico no depende del paciente. Las células mesenquimales de tejido de cordón umbilical son inmunológicamente privilegiadas, lo que quiere decir que no generan un rechazo, su uso clínico en pacientes no emparentados es posible. Estas células provienen de un donante óptimo según criterios predefinidos establecidos."
            },
            {
                id: 6,
                question: "Si las células son de un donante ¿Por qué no producen rechazo? ¿Puedo contraer alguna enfermedad?",
                description: `Diversos estudios clínicos en pacientes, han demostrado que las células madre mesenquimales son inmunoevasivas, es decir, no son detectadas por el sistema inmune, y por lo tanto no son rechazadas. Esto se debe a que este tipo particular de células carece de antígenos de superficie (MHC-II y expresión de moléculas co-estimuladoras) detectables por el sistema inmune del paciente. Adicionalmente, las células mesenquimales poseen un efecto inmunomodulador que modifica positivamente la respuesta de diversas células del sistema inmune (linfocitos T, células dendríticas y otras). \n
                        Todos nuestros productos son procesados en un laboratorio que cuenta con Certificación de Gestión de Calidad (ISO 9001:2015) en normativa GMP (del inglés Good Manufacturing Practices), lo que asegura la calidad y bioseguridad del producto. Cada tejido que ingresa es verificado exhaustivamente siguiendo las guías de Buenas Prácticas Clínicas definidas por la FDA1, que contemplan estrictos análisis respecto de la salud del donante, incluyendo la determinación de ausencia de enfermedades transmisibles. Adicionalmente, cada vial cuenta con un certificado de análisis, que es entregado al paciente, y que señala la bioseguridad y especificación de cada terapia, asegurando que esté libre de contaminantes que puedan causar enfermedad.`
            },
            {
                id: 7,
                question: "¿Es posible que estas células se transformen en un tumor?",
                description: "Diversos estudios pre-clínicos y en pacientes realizados a nivel mundial, han demostrado la seguridad de la terapia celular, tanto autóloga como alogénica. Existen estudios de seguimiento a 10 años de pacientes tratados que demuestran la seguridad clínica de este tipo de terapias, donde no se ha observado la formación de tumores."
            },
        ],
    },
    {
        idName: 'treatments',
        section: 'Tratamientos',
        questions: [
            {
                id: 8,
                question: "¿En qué consiste el tratamiento para artrosis?",
                description: "Es un tratamiento médico ambulatorio que se administra mediante una infiltración local intraarticular directamente en la articulación afectada. Puede requerir uso de anestesia."
            },
            {
                id: 9,
                question: "¿Cuáles son los beneficios del tratamiento con células madre para artrosis?",
                description: `Se ha demostrado una mejoría sintomática significativa en la disminución del dolor, de la inflamación y de la rigidez, lo que conlleva a un incremento de la función articular y movilidad. Los beneficios comienzan a observarse entre 1 a 4 semanas, con mayor énfasis a los 6 meses. Aún a los 24 meses de seguimiento, los pacientes han indicado que mantienen los beneficios. El tratamiento posee un efecto ampliamente superior en comparación a otras terapias (ácido hialurónico, antiinflamatorios, corticoides u otras). \n
                La efectividad del tratamiento es de un 80%, es decir, 8 de cada 10 pacientes indican observar una mejora significativa en su calidad de vida.`
            },
            {
                id: 10,
                question: "¿El tratamiento regenera el cartílago?",
                description: "Si bien se han observado casos de regeneración de cartílago y hueso subcondral en lesiones focales condrales y osteocondrales y en casos de necrosis avascular, los resultados clínicos indican que el efecto de la terapia celular está mayormente sustentado por un aspecto sintomático más que estructural."
            },
            {
                id: 11,
                question: "¿Cuáles son los efectos adversos de un tratamiento con células madre para artrosis?",
                description: `La infiltración intra-articular puede provocar dolor y/o hematoma en el sitio de punción. Existe un riesgo muy bajo (1 de cada 2.000) de causar infección de la articulación cuando se realiza una infiltración local, independiente del producto administrado. \n
                Posterior a la administración de células madre, hasta un 20% de los pacientes experimenta mayor dolor, inflamación o sensación de temperatura, síntomas que pueden durar entre 24 a 96 horas y que disminuyen paulatinamente sin tratamiento. Ante ello, se recomienda la aplicación de compresas frías local, reposo relativo sin actividad física, y restricción de caminatas y traslados. \n
                En caso de persistir la inflamación de la zona y aparecer fiebre, debe consultar con su médico tratante. No se han observado otro tipo de efectos adversos ni al corto ni largo plazo.`
            },
            {
                id: 12,
                question: "¿Existen contraindicaciones para tratamientos con células madre en traumatología?",
                description: "El tratamiento no puede ser administrado en pacientes con antecedentes de alergia grave o anafilaxis a la penicilina, estreptomicina, anfotericina B, o proteína de suero bovina. Debido a que no existen estudios en este tipo de población, no se recomienda su uso en embarazadas o personas que tengan un evento neoplásico activo."
            },
            {
                id: 13,
                question: "¿Cuáles son las ventajas de una terapia celular alogénica versus una terapia autóloga?",
                description: `Una terapia autóloga es aquella en la que donante y el receptor de las células es el mismo paciente, mientras que una terapia alogénica es aquella en la que el donante y el receptor son diferentes personas, tal es el caso. Las ventajas de una terapia celular alogénica de tejido de cordón umbilical radican en que: \n
                        a. No se requiere de un procedimiento costoso e invasivo como en el caso de las terapias autólogas, donde la muestra es obtenida por ejemplo, a partir de un aspirado de médula ósea o tejido graso. \n
                        b. Las células autólogas normalmente tienen un mayor grado de senescencia (“envejecimiento”), en particular en pacientes sobre los 30 años de edad, lo que implica una menor capacidad terapéutica. La terapia alogénica es independiente de la edad del paciente. \n
                        c. La terapia celular alogénica es compatible con cualquier persona, dado que las células mesenquimales son invisibles al sistema inmune del paciente y por ende no hay rechazo. \n
                        Los últimos avances en el área de medicina regenerativa se están centrando en el uso de terapias alogénicas y no de terapias autólogas, dada su mayor porcentaje de liberación de moléculas bioactivas, su homogeneidad, seguridad y eficacia.`
            },
            {
                id: 14,
                question: "¿Cómo puedo saber si soy candidato a la terapia celular?",
                description: "La terapia con células madre es recomendada después de haber probado tratamientos convencionales sin haber obtenido efectos. La terapia con células madre no curan todas las enfermedades ni son efectivas para cualquier paciente. Es necesario que consulte con un médico del equipo Novocel, quien en base a una evaluación clínica de sus síntomas, historial médico y examen físico e imágenes, determinará si es candidato o no."
            },
            
            {
                id: 15,
                question: "¿En Chile existe regulación para este tipo de terapias?",
                description: "En Chile y otros países de Latinoamérica aún no existe regulación sanitaria para la terapia celular. Considerando que no hay una ley con la especificidad técnica que regule las actividades relacionadas con la aplicación de células madre (procesos de producción, almacenamiento, distribución, investigación científica-biomédica), cada empresa o médico decide qué nivel de seguridad, eficacia o evaluación de efectos adversos aplicará en sus pacientes. Nuestros productos se basan en altos estándares internacionales, validados en el marco de un laboratorio GMP. En Novocel resguardamos la calidad y bioseguridad ante todo. La rigurosidad profesional es fundamental."
            },
        ],
    },
    /* {
        section: '',
        questions: [

        ],
    },    */    
]

export default faqs;