<template>
    <div class="card" :class="{[backgroundColor]: true, [responsiveWidth]: true}">
        <div>
            <p class="title">{{ title }}</p>
            <div class="items">
                <ul>
                    <li v-for="item in items" :key="item.text">
                        <i v-if="item.check" class="ri-check-line"></i>
                        <i v-else class="ri-close-fill uncheck"></i>
                        <span :class="{ 'uncheck': !item.check }">{{ item.text }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="border-violet">
            <div>
                <div class="price">
                    <p class="number">{{ price }}</p>
                    <p class="time">{{ time }}</p>
                </div>
                <p class="explanation" v-if="explanation">
                    * {{ explanation }}
                </p>
            </div>
            <div>
                <ButtonAction link="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" :targetBlank=true text="Agendar consulta" class="schedule"/>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonAction from './ButtonAction.vue';
export default {
    name: 'CardPrice',
    components: {
        ButtonAction,
    },
    props:  {
        title: {
            type: String,
        },
        backgroundColor: {
            type: String,
        },
        responsiveWidth:{
            type: String,
        },
        explanation: {
            type: String,
        },
        price:{
            type: String,
        },
        time:{
            type: String,
        },
        items: {
            type: Array,
        }
    },
}
</script>

<style scoped>
.schedule {
    margin-top: 1rem;
    width: 100%;
}
.card{
    border-radius: 1rem;
    background-color: var(--white);
    padding: 1.5rem;
    display: flex;
    border: none;
    justify-content: space-between;
    min-height: 450px;
    margin-bottom: 2rem;
}
.card.soft-violet{
    background-color: var(--soft-hover-violet);
}
.card.white{
    box-shadow: var(--soft-shadow);
}
.card.background{
    background-color: var(--background);
}
.title{
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
}
ul {
    list-style: none;
}
ul i{
    margin-left: -32px;
    padding-right: 17px;
}
ul li{
    padding: 3px 0;
}
.uncheck{
    opacity: 0.4;
}
.border-violet{
    margin-top: 1rem;
    border-top: 2px solid var(--dark-violet);
}
.price{
    margin-top: 1rem;
}
.price .number{
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
}
.price .time{
    font-size: 1.2rem;
    margin: 0;
    position: relative;
    top: 3px;
    padding-bottom: 1rem;
}
.explanation{
    position: relative;
    bottom: 5px;
    font-size: 0.9rem;
}
/* RESPONSIVE */
@media (min-width:575px) {
    .title{
        font-size: 2.9rem;
        line-height: 3rem;
        margin-bottom: 1.5rem;
        min-height: 100px;
    }
}
@media (min-width:992px) {
    .card.col-lg-4{
        margin: 1rem;
        width: calc(50% - 2rem);
    }
    .card.col-lg-6{
        margin: 1rem;
        width: calc(50% - 2rem);
    }
    .price{
        display: flex;
        align-items: center;
    }
    .price .time{
        padding-left: 0.8rem;
        padding-bottom: 0;
    }
}
@media (min-width:1200px) {
    .card.col-lg-4{
        margin: 1rem;
        width: calc(33.33% - 2rem);
    }
}
</style>