<template>
    <section id="presentation">
        <DecorationCurveLeft class="decoration-curve-left" />
        <div class="text-info">
            <SectionName v-if="sectionName" class="mb-4" :text=sectionName :alignCenter=alignCenter />
            <TitlePrincipal 
                class="col-12 col-lg-10 col-xl-9 title" 
                :class="{ 'align-left': !alignCenter }"
                :alignCenter=alignCenter
                :ppalText=ppalText
                :secondText=secondText
            />
        </div>
    </section>
</template>

<script>
import TitlePrincipal from '@/components/TitlePrincipal.vue';
import DecorationCurveLeft from '@/components/DecorationCurveLeft.vue';
import SectionName from './SectionName.vue';

export default {
    name: 'SectionIntroTitle',
    components: {
        TitlePrincipal,
        DecorationCurveLeft,
        SectionName,
    },
    props:  {
        sectionName: {
            type: String,
        },
        ppalText: {
            type: String,
        },
        secondText: {
            type: String,
        },
        alignCenter: {
            type: Boolean,
            default: false,
        },
    },
}
</script>


<style scoped>
#presentation {
    width: 100%;
}
.decoration-curve-left {
    position: absolute;
    right: -80px;
    top: 200px;
    transform: scale(0.80);
}
.title {
    margin: auto;
}
.title.align-left{
    margin: 0;
}
.text-info{
    position: relative;
    z-index: 2;
    padding-top: 55px;
}
/* RESPONSIVE */
@media (min-width:768px) {
    .decoration-curve-left {
        top: 120px;
        right: -45px;
        transform: initial;
    }
    .text-info {
        padding-top: 80px;
    }
}
@media (min-width:992px) {
    .decoration-curve-left {
        right: -75px;
    }
}
</style>