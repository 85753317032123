/* IMAGES */
import ChondropathyPng from '../../assets/img/photos/png/chondropathy_small.png';
import ChondropathyJpg from '../../assets/img/photos/jpg/chondropathy_small.jpg';
import ChondropathyWebp from '../../assets/img/photos/webp/chondropathy_small.webp';

import DeportPng from '../../assets/img/photos/png/deport_small.png';
import DeportJpg from '../../assets/img/photos/jpg/deport_small.jpg';
import DeportWebp from '../../assets/img/photos/webp/deport_small.webp';

import OsteoarthritisPng from '../../assets/img/photos/png/artro_small.png';
import OsteoarthritisJpg from '../../assets/img/photos/jpg/artro_small.jpg';
import OsteoarthritisWebp from '../../assets/img/photos/webp/artro_small.webp';


/* PATOLOGÍAS INFO */

let pathology = [
    {
        id: 1,
        pathology: 'Lesiones deportivas',
        description: 'Las lesiones deportivas se producen al hacer ejercicio físico, ya sea competitivo o recreativo. Pueden ser traumáticas, por exceso de uso o sobreesfuerzo. Se debe prevenir, diagnosticar y tratar adecuadamente cada caso. Algunas lesiones comunes son: fascitis plantar, epicondilitis, desgarro del manguito rotador o lesión del ligamento cruzado anterior. Muchas se pueden tratar sin cirugía, de manera ambulatoria en sala de procedimientos.',
        srcWebp: DeportWebp,
        srcPng: DeportPng,
        srcJpg: DeportJpg, 
        altImg: "Hombre tomándose el pie", 
        active: true,
    },
    {
        id: 2,
        pathology: 'Condropatía',
        description: 'La condropatía es un término que se utiliza para describir una condición en la que el cartílago que recubre la rótula (rodilla) se vuelve delgado y blando. Esto puede ocurrir debido a un desgaste natural del cartílago con el tiempo o como resultado de una lesión. La condropatía también se conoce como "rodilla del corredor" debido a que es común en personas que practican deportes de carrera.',
        srcWebp: ChondropathyWebp,
        srcPng: ChondropathyPng,
        srcJpg: ChondropathyJpg, 
        altImg: "Persona poniéndose una venda en la rodilla",
        active: false,
    },
    {
        id: 3,
        pathology: 'Artrosis',
        description: 'La artrosis es una enfermedad de las articulaciones que produce desgaste del cartílago y osteofitos. Causa dolor, rigidez y limitación del movimiento. Afecta sobre todo a personas mayores, pero también a otras por lesiones, sobrepeso, trastornos, genética o profesiones. En Clínica Novocel tratamos la artrosis con células madre y ácido hialurónico, con más del 90% de efectividad.',
        srcWebp: OsteoarthritisWebp,
        srcPng: OsteoarthritisPng,
        srcJpg: OsteoarthritisJpg, 
        altImg: "Mujer tomándose la mano", 
        active: false,
    },
]

export default pathology;