<template>
    <section id="presentation">
        <div class="text-info">
            <SectionName v-if="sectionName" class="mb-4" :text=sectionName :alignCenter=alignCenter />
            <TitlePrincipal 
                class="col-12 col-lg-10 col-xl-10 title" 
                :alignCenter=alignCenter
                :ppalText=ppalText
                :secondText=secondText
            />
        </div>
    </section>
    <div class="image">
        <picture>
            <source :srcset=srcWebp type="image/webp">
            <source :srcset=srcJpg type="image/jpeg" />
            <source :srcset=srcPng type="image/png">
            <img class="col-11 col-lg-10 col-xl-9 " :src=srcWebp :alt=altImg />
        </picture>
    </div>
</template>
<script>
import TitlePrincipal from '@/components/TitlePrincipal.vue';
import SectionName from '@/components/SectionName.vue';

export default {
    name: 'BlogPostTitle',
    components: {
        TitlePrincipal,
        SectionName,
    },
    props:  {
        sectionName: {
            type: String,
        },
        ppalText: {
            type: String,
        },
        secondText: {
            type: String,
        },
        alignCenter: {
            type: Boolean,
            default: false,
        },
        srcWebp: {
            type: String,
        },
        srcPng: {
            type: String,
        },
        srcJpg: {
            type: String,
        },
        altImg: {
            type: String,
        },
    },
}
</script>


<style scoped>
#presentation {
    width: 100%;
    background-color: var(--background);
    border-radius: 2rem;
}
.title {
    margin: auto;
    margin-bottom: 6rem;
}
.text-info{
    position: relative;
    z-index: 2;
    margin: 55px 0;
    padding: 40px;
}
.image{
    position: relative;
    top: -9.5rem;
    margin-bottom: -9.5rem;
}
.image img{
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .title{
        line-height: 90px;
    }
}
</style>