/* IMAGES */
/* CELLS */
import CellSvg from '@/assets/img/icons/svg/celula.svg'
import CellPng from '@/assets/img/icons/png/celula.png'
import CellWebp from '@/assets/img/icons/webp/celula.webp'
import CellsJpg from '@/assets/img/photos/jpg/cells.jpg'
import CellsPng from '@/assets/img/photos/png/cells.png'
import CellsWebp from '@/assets/img/photos/webp/cells.webp'

/* PLASMA */
import BloodSvg from '@/assets/img/icons/svg/sangre.svg'
import BloodPng from '@/assets/img/icons/png/sangre.png'
import BloodWebp from '@/assets/img/icons/webp/sangre.webp'
import PlasmaJpg from '@/assets/img/photos/jpg/plasma.jpg'
import PlasmaPng from '@/assets/img/photos/png/plasma.png'
import PlasmaWebp from '@/assets/img/photos/webp/plasma.webp'

/* HYALURONIC */
import SyringeSvg from '@/assets/img/icons/svg/jeringa.svg'
import SyringePng from '@/assets/img/icons/png/jeringa.png'
import SyringeWebp from '@/assets/img/icons/webp/jeringa.webp'
import HyaluronicJpg from '@/assets/img/photos/jpg/hyaluronic.jpg'
import HyaluronicPng from '@/assets/img/photos/png/hyaluronic.png'
import HyaluronicWebp from '@/assets/img/photos/webp/hyaluronic.webp'

/* THERAPIES */
import HealthSvg from '@/assets/img/icons/svg/salud.svg'
import HealthPng from '@/assets/img/icons/png/salud.png'
import HealthWebp from '@/assets/img/icons/webp/salud.webp'
import TherapiesJpg from '@/assets/img/photos/jpg/therapies.jpg'
import TherapiesPng from '@/assets/img/photos/png/therapies.png'
import TherapiesWebp from '@/assets/img/photos/webp/therapies.webp'



/* TREATMENTS INFO */

let treatments = [
    {
        idName: 'stem-cells',
        section: 'Células madre mesenquimales',
        srcWebp: CellWebp,
        srcPng: CellPng,
        srcSvg: CellSvg,
        altImg: "Ícono de células madre mesenquimales",
        highlightedText: 'La terapia celular con células madre es una alternativa médica de última generación que está revolucionando el tratamiento de las lesiones traumatológicas, ya que ha demostrado ser segura y efectiva en patologías no responsivas.',
        firstTextPost: `Las células madre mesenquimales son células que tenemos en nuestro cuerpo y que con el paso de los años las perdemos en cantidad y calidad, razón por la cual resulta útil administrar un tratamiento con células de alto potencial. \n
        En Clínica Novocel trabajamos con células derivadas de tejido de cordón umbilical de un “donante universal” por tener beneficios biológicos superiores. Estas células tienen la capacidad de multiplicarse, transformarse en distintos tipos de tejidos, y secretar sustancias reparadoras. El tratamiento con células madre es ambulatorio y consiste en una única infiltración (inyección) intraarticular o directamente en la zona de lesión (tendones o ligamentos), lo que permite disminuir el dolor y la inflamación, deteniendo el progreso de la enfermedad y aportando un potencial regenerativo para reparar el daño del tejido.`,
        secondTextPost: `El procedimiento dura alrededor de 45 minutos total y considera: la firma del consentimiento informado, preparación para infiltración, infiltración, y recomendaciones post-operatorias. Para el procedimiento se utiliza anestesia local en la zona, y cuando es requerido la infiltración se apoya con guía ecográfica. \n
        Si las opciones de tratamientos convencionales como la kinesiología, el consumo de antiinflamatorios o infiltraciones con corticoides, o plasma rico en plaquetas no te han proporcionado un alivio significativo, es posible que un tratamiento con células madre te pueda ayudar. \n
        Ten en cuenta que, para lograr un buen resultado con un tratamiento de terapia celular, es importante que tu evaluación clínica esté en manos de un médico profesional ¡Agenda tu evaluación con nosotros!`,
        postImgWebp: CellsWebp,
        postImgJpg: CellsJpg,
        postImgPng: CellsPng,
    },
    {
        idName: 'plasma',
        section: 'Plasma rico en plaquetas',
        srcWebp: BloodWebp,
        srcPng: BloodPng,
        srcSvg: BloodSvg,
        altImg: "Ícono de gota de sangre",
        highlightedText: `El PRP se utiliza para tratar lesiones musculares, tendinosas y articulares.`,
        firstTextPost: `El plasma rico en plaquetas (PRP) es un suero obtenido a partir de la propia sangre del paciente que contiene una alta concentración de plaquetas. Estas plaquetas son ricas en factores de crecimiento que pueden ayudar en la curación de lesiones y en la regeneración del tejido. El PRP se utiliza comúnmente en tratamientos de medicina regenerativa, como en el tratamiento de lesiones deportivas, en la cirugía ortopédica, y en la medicina estética.`,
        secondTextPost: `El procedimiento ambulatorio de una única sesión con PRP se divide en 3 etapas: extracción, procesamiento, e infiltración. En la etapa de extracción, se extrae una muestra de sangre del paciente desde la cual se obtendrá el plasma rico en plaquetas. En la etapa de procesamiento, la muestra extraída se procesa utilizando un equipo especializado y un protocolo de procesamiento. Finalmente, en la etapa de infiltración, la muestra procesada es administrada al paciente mediante una infiltración (inyección) en la zona de lesión. \n
        Los beneficios de infiltrarse con plasma rico en plaquetas (PRP) pueden incluir: Mejora de la curación de lesiones: Los factores de crecimiento presentes en las plaquetas ayudan a acelerar el proceso de curación de lesiones musculares, tendinosas y articulares. Alivio del dolor: El PRP puede ayudar a aliviar el dolor en las articulaciones y los tejidos blandos. Regeneración del tejido: El PRP puede ayudar a estimular la regeneración del cartílago, la tendones y los ligamentos dañados. Reducción de la inflamación: El PRP también puede ayudar a reducir la inflamación en las articulaciones y tejidos blandos, lo que puede ayudar a aliviar el dolor y mejorar la movilidad.`,
        postImgWebp: PlasmaWebp,
        postImgJpg: PlasmaJpg,
        postImgPng: PlasmaPng,
    },
    {
        idName: 'hyaluronic-acid',
        section: 'Ácido hialurónico',
        srcWebp: SyringeWebp,
        srcPng: SyringePng,
        srcSvg: SyringeSvg,
        altImg: "Ícono de jeringa",
        highlightedText: `El ácido hialurónico genera una alta lubricación en la articulación, mejora la absorción de los impactos, disminuye el contacto entre los huesos y por consiguiente el dolor.`,
        firstTextPost: `El ácido hialurónico es una sustancia natural que se encuentra en el cuerpo humano. Es un componente del líquido sinovial de las articulaciones, clave para su biomecánica, y que en los pacientes con artrosis, su concentración y peso molecular se encuentran disminuidos. \n
        La viscosuplementación o la infiltración intraarticular de ácido hialurónico tiene el objetivo de disminuir el dolor articular y mejorar el estado funcional de la articulación artrósica, y consiste en inyectar este compuesto directamente en una articulación afectada. El ácido hialurónico actúa como un lubricante y amortiguador natural en las articulaciones, lo que ayuda a reducir la inflamación y el dolor. La técnica se utiliza para tratar artrosis de rodilla, cadera, cadera u otras zonas.`,
        secondTextPost: `El procedimiento es ambulatorio, se realiza con anestesia local y suele durar entre 15 y 30 minutos. Es importante destacar que esta técnica no es recomendada para personas con infecciones activas en el área a tratar, ni tampoco para personas con alergia al ácido hialurónico. \n
        Agenda tu evaluación, disminuye el dolor y la fricción al caminar.`,
        postImgWebp: HyaluronicWebp,
        postImgJpg: HyaluronicJpg,
        postImgPng: HyaluronicPng,
    },
    {
        idName: 'combined-therapies',
        section: 'Terapias combinadas',
        srcWebp: HealthWebp,
        srcPng: HealthPng,
        srcSvg: HealthSvg,
        altImg: "Ícono de bolso de médico",
        highlightedText: `La terapia combinada para artrosis que administramos ha sido validada en evidencia clínica a dos años de seguimiento, utilizándose para artrosis en grados moderados y severos.`,
        firstTextPost: `La terapia combinada en medicina regenerativa se refiere a la utilización de tratamientos compatibles en conjunto, con el objetivo de mejorar los resultados en la curación y la regeneración de tejidos. \n
        Una de las ventajas de utilizar una terapia combinada en medicina regenerativa es que permite abordar múltiples aspectos de una lesión o enfermedad de manera simultánea. Por ejemplo, al combinar ácido hialurónico con células madre, se combina la lubricación de la articulación que otorga el ácido hialurónico, con la estimulación del cartílago y la reducción de la inflamación de las células madre, obteniendo mejores resultados clínicos que al administrar cada uno de los tratamientos por separado.`,
        secondTextPost: `La terapia combinada para artrosis mejora la movilidad en pacientes no responsivos a tratamientos convencionales, logrando retrasar o evitar la colocación de prótesis articular. Es un procedimiento ambulatorio, de una única sesión. \n
        Recuerda que para saber si eres candidato al tratamiento, debes asistir a una evaluación médica con nuestros especialistas. Agenda tu hora para que pronto empecemos tu recuperación.`,
        postImgWebp: TherapiesWebp,
        postImgJpg: TherapiesJpg,
        postImgPng: TherapiesPng,
    },

    /* {
        section: '',
        questions: [
                id: 1,
                question: "¿Qué es ArtroStem?",
                description: 
        ],
    },    */    
]

export default treatments;