<template>      
    <section id="team" class="carousel-cards-container">
        <TitleSecodary ppalText="Equipo de Clínica Novocel" class="title" />
        <swiper 
        :breakpoints="{
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        }" 
        :spaceBetween="30" 
        :modules="modules" 
        :autoplay="{
            delay: 0,
            disableOnInteraction: true,
        }" 
        :loop="true" 
        :speed="4000" 
        :centeredSlides="true" 
        class="mySwiper m-0">
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Humberto' 
                name="Dr. Humberto Verdugo Marchese"
                profession="Director Médico"
                description="Médico cirujano de la Universidad de Valparaíso, 
                            especialista en Traumatología y Ortopedia."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Cinthia' 
                name="Ing. Cinthia Briceño Rosas"
                profession="Directora Científica"
                description="Ingeniera en biotecnología de la Universidad Andrés Bello.
                            Especialista en medicina regenerativa y proyectos de investigación."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink="Claudio" 
                name="Héctor Flores"
                profession="Traumatólogo"
                description="Médico cirujano especialissta en traumatología"
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Humberto' 
                name="Dr. Humberto Verdugo Marchese"
                profession="Director Médico"
                description="Médico cirujano de la Universidad de Valparaíso, 
                            especialista en Traumatología y Ortopedia."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Cinthia' 
                name="Ing. Cinthia Briceño Rosas"
                profession="Directora Científica"
                description="Ingeniera en biotecnología de la Universidad Andrés Bello.
                            Especialista en medicina regenerativa y proyectos de investigación."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink="Claudio" 
                name="Héctor Flores"
                profession="Traumatólogo"
                description="Médico cirujano especialissta en traumatología"
                />
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Autoplay } from 'swiper/modules';
import ImageTextCard from '@/components/ImageTextCard.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import Cinthia from '../../assets/img/team/jpg/Cinthia.jpg';
import Claudio from '../../assets/img/team/jpg/Claudio.jpg';
import Humberto from '../../assets/img/team/jpg/Humberto.jpg';

export default {
    name: "SectionTeam",
    components: {
        TitleSecodary,
        Swiper,
        SwiperSlide,
        ImageTextCard,
    },
    setup() {
        return {
            modules: [FreeMode, Autoplay],
        };
    },
    data() {
        return {
            Cinthia: Cinthia,
            Claudio : Claudio, 
            Humberto : Humberto,
        }
    },
};
</script>


<style scoped>
#team{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 4rem;
    max-width: 1600px;
    margin: auto;
}
.title{
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 600px;
    align-self: center;
}
.mySwiper {
    cursor: grab;
    overflow: visible;
}
.carousel-cards-container {
    margin-top: 3rem;
}
#team .swiper-slide:not(.swiper-slide-active),
#team .swiper-slide {
    transition: all 2s ease-in-out;
}
#team .swiper-slide:not(.swiper-slide-active ){
    transform: scale(0.9);
}
#team .swiper-slide-active {
    transform: scale(1.1) translateY(38px);
}
</style>
