/* PRICES */

let prices = [
    {
        id: 1,
        title: 'Consulta traumatológica',
        price: '$60.000',
        time: 'por sesión',
        responsiveWidth: 'col-lg-4',
        backgroundColor: 'white',
        items: [
            {
                check: true,
                text: 'Revisión de exámenes.',
            },
            {
                check: true,
                text: 'Generación de órdenes médicas según sea requerido.',
            },
            {
                check: true,
                text: 'Anamnesis de paciente.',
            },
            {
                check: true,
                text: 'Evaluación traumatológica.',
            },
            {
                check: true,
                text: 'Atención particular, reembolsable por isapres y seguros.',
            },
            {
                check: false,
                text: 'Toma de exámenes.',
            },
            {
                check: false,
                text: 'Fonasa.',
            },
        ]
    },
    {
        id: 2,
        title: 'Plasma rico en plaquetas',
        price: '$340.000',
        time: '1 hora',
        explanation: 'Sujeto a evaluación médica',
        responsiveWidth: 'col-lg-4',
        backgroundColor: 'soft-violet',
        items: [
            {
                check: true,
                text: 'Toma de muestras.',
            },
            {
                check: true,
                text: 'Procesamiento e infiltración del concentrado plaquetario.',
            },
            {
                check: true,
                text: 'Honorarios médicos.',
            },
            {
                check: true,
                text: 'Insumos médicos.',
            },
            {
                check: true,
                text: 'Consulta científica.',
            },
            {
                check: true,
                text: 'Guía ecográfica de ser requerido.',
            },
            {
                check: true,
                text: 'Atención particular, reembolsable por isapres y seguros.',
            },
            {
                check: false,
                text: 'Fonasa',
            },
        ]
    },
    {
        id: 3,
        title: 'Ácido hialurónico',
        price: '$240.000',
        time: '20 minutos',
        explanation: 'Sujeto a evaluación médica',
        responsiveWidth: 'col-lg-4',
        backgroundColor: 'background',
        items: [
            {
                check: true,
                text: 'Ácido hialurónico de origen Suizo.',
            },
            {
                check: true,
                text: 'Infiltración del bioinsumo.',
            },
            {
                check: true,
                text: 'Honorarios médicos.',
            },
            {
                check: true,
                text: 'Insumos médicos.',
            },
            {
                check: true,
                text: 'Consulta científica.',
            },
            {
                check: true,
                text: 'Guía ecográfica de ser requerido.',
            },
            {
                check: true,
                text: 'Atención particular, reembolsable por isapres y seguros.',
            },
            {
                check: false,
                text: 'Fonasa.',
            },
        ]
    },
    {
        id: 4,
        title: 'Terapia combinada células madre',
        price: '$950.000',
        time: '45 minutos',
        explanation: 'Sujeto a evaluación médica',
        responsiveWidth: 'col-lg-6',
        backgroundColor: 'white',
        items: [
            {
                check: true,
                text: '20 millones de células madre mesenquimales.',
            },
            {
                check: true,
                text: 'Ácido hialurónico de origen Suizo.',
            },
            {
                check: true,
                text: 'Infiltración del bioinsumo.',
            },
            {
                check: true,
                text: 'Honorarios médicos.',
            },
            {
                check: true,
                text: 'Insumos médicos.',
            },
            {
                check: true,
                text: 'Consulta científica.',
            },
            {
                check: true,
                text: 'Guía ecográfica de ser requerido.',
            },
            {
                check: true,
                text: 'Atención particular, reembolsable por isapres y seguros.',
            },
            {
                check: false,
                text: 'Fonasa.',
            },
        ]
    },
    {
        id: 5,
        title: 'Consulta médica especialista dolor',
        price: '$50.000',
        time: '30 minutos',
        responsiveWidth: 'col-lg-6',
        backgroundColor: 'soft-violet',
        items: [
            {
                check: true,
                text: 'Revisión de exámenes.',
            },
            {
                check: true,
                text: 'Generación de órdenes médicas según sea requerido.',
            },
            {
                check: true,
                text: 'Anamnesis de paciente.',
            },
            {
                check: true,
                text: 'Evaluación médica.',
            },
            {
                check: true,
                text: 'Atención particular, reembolsable por isapres y seguros.',
            },
            {
                check: false,
                text: 'Toma de exámenes.',
            },
            {
                check: false,
                text: 'Fonasa.',
            },
        ]
    },

]

export default prices;