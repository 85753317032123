<template>
    <div class="filter-name">
        <picture v-if="srcWebp">
            <source :srcset=srcWebp type="image/webp">
            <source :srcset=srcPng type="image/png">
            <source :srcset=srcSvg type="image/svg+xml">
            <img class="filter-img" :src=srcWebp :alt=altImg />
        </picture>
        <p>
            {{text}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'ButtonFilterImg',
    props: {
        text: {
            type: String,
        },
        alignCenter: {
            type: Boolean,
            default: false,
        },
        srcWebp: {
            type: String,
        },
        srcPng: {
            type: String,
        },
        srcSvg: {
            type: String,
        },
        altImg: {
            type: String,
        },
    },
}
</script>

<style scoped>
.filter-img{
    width: 50px;
    margin-left: 0.3rem;
}
.filter-name{
    margin-bottom: 1rem;
    padding: 15px;
    display: flex;
    align-items: center;
    border: 1px solid var(--dark-violet);
    border-radius: 0.5rem;
    width: 100%;
    transition: all 0.5s ease-in-out;
    will-change: transform;
    cursor: pointer;
}
.filter-name:hover{
    transform: scale(1.02);
}
.filter-name p {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: 600;
    padding: 0 0.5rem 0 1rem;
}
.filter-name:hover, .selected {
    background-color: var(--background);
    border: 1px solid var(--background);
    color: var(--dark-violet);
}
.selected p{
    font-weight: 700;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .filter-name p {
        width: 100%;
        font-size: 1.3rem;
        line-height: 1.4rem;
    }
}
</style>