<template>
    <div class="accordion-cards">
        <div class="accordion" id="accordion">
            <template v-for="item in ArrayInfo" :key="item.id">
                <div class="accordion-item">
                    <h3 class="accordion-header" :id="`heading${item.id}`">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${item.id}`" aria-expanded="true" :aria-controls="`collapse${item.id}`">
                            <div class="photo" v-if="item.srcWebp">
                                <picture>
                                    <source :srcset=item.srcWebp type="image/webp">
                                    <source :srcset=item.srcJpg type="image/jpeg" />
                                    <source :srcset=item.srcPng type="image/png">
                                    <img :src=item.srcWebp :alt=altImg />
                                </picture>
                            </div>
                            <div class="member-info" v-if="item.name">
                                <h4 v-if="item.name">{{item.name}}</h4>
                                <p v-if="item.profession" class="profession">{{ item.profession }}</p>
                            </div>
                            <div class="question-info">
                                <h5 v-if="item.question">{{item.question}}</h5>
                            </div>
                        </button>
                    </h3>
                    <div :id="`collapse${item.id}`" class="accordion-collapse collapse " :aria-labelledby="`heading${item.id}`" data-bs-parent="#accordion">
                        <div class="accordion-body">
                            <p class="description">{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccordionCards',
    components: {
    },
    props:  {
        ArrayInfo: {
            type: Array,
        }
    },
}
</script>

<style scoped>
.photo img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
.accordion-item{
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 0;
}
.accordion-button{
    background-color: var(--background);
    transition: all 0.1s ease-in-out;
}
.accordion-button:not(.collapsed){
    background-color: var(--soft-hover-violet);
    box-shadow: none;
}
.accordion-collapse{
    background-color: var(--soft-hover-violet);
}
.accordion{
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0 rgba(13, 110, 253, 0),
}
.accordion-body{
    padding-top: 0;
    padding-bottom: 1rem;
}
.member-info{
    margin-left: 1rem;
}
p.profession, h4{
    margin-bottom: 0;
}
p.description{
    margin-bottom: 0;
    padding: 0.8rem 0 0.3rem 0;
}
h4{
    font-weight: 600;
}
.question-info h5{
    margin: 0;
}
@media (max-width:768px) {
    .accordion-button{
        padding: 0.6rem;
        transition: all 0.2 ease-in-out;
    }
}
</style>