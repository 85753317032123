<template>
    <footer :class="{'soft-violet-back': !violetAnimation}">
        <div v-if="violetAnimation" class="appointment">
            <swiper 
            :breakpoints="{
                768: {
                    slidesPerView: 1.4,
                },
            }" 
            :spaceBetween="20" 
            :modules="modules" 
            :autoplay="{
                delay: 0,
                disableOnInteraction: true,
            }"
            :loop="true" 
            :speed="4500" 
            class="mySwiper m-0">
                <swiper-slide class="swiper-slide-text">
                    <p class="title"><span class="secondText">Agende ahora</span> su consulta médica</p> 
                </swiper-slide>
                <swiper-slide class="swiper-slide-text">
                    <p class="title"><span class="secondText">Agende ahora</span> su consulta médica</p> 
                </swiper-slide>
                <swiper-slide class="swiper-slide-text">
                    <p class="title"><span class="secondText">Agende ahora</span> su consulta médica</p> 
                </swiper-slide>
            </swiper>
        </div>
        <div v-if="violetAnimation" class="appointment-button">
            <ButtonAction link="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" :targetBlank=true text="Agendar consulta" class="schedule" backColor="white"/>
        </div>
        <FooterSections />
    </footer>   
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Autoplay } from 'swiper/modules';
import FooterSections from './FooterSections.vue';

export default {
    name: "FooterBar",
    components: {
        Swiper,
        SwiperSlide,
        ButtonAction,
        FooterSections,
    },
    props:  {
        violetAnimation: {
            type: Boolean,
            default: true,
        }
    },
    setup() {
        return {
            modules: [FreeMode, Autoplay],
        };
    },
};
</script>

<style scoped>
footer{
    min-width: 100vw;
    position: relative;
    right: 31px; 
    background-color: var(--dark-violet);
    margin-bottom: -62px;
}
.appointment{
    padding: 4rem 0 0 0;
}
.appointment-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 4rem 0;
}
.title{
    color: var(--white);
    font-size: 40px;
    line-height: 45px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 20px;
    max-width: fit-content;
    text-align: center;
}
.secondText{
    color: var(--light-violet);
}
.schedule {
    min-width: 200px;
    margin-top: 2rem;
    font-weight: 800;
    transform: scale(1.1);
}
.schedule:hover{
    transform: scale(1.2);
}
footer.soft-violet-back{
    background-color: var(--background);
}
/* RESPONSIVE */
@media (min-width:576px) {
    .title{
        font-size: 50px;
        line-height: 55px;
    }
}
@media (min-width:768px) {
    footer{
        right: 45px;
    }
    .swiper-slide-text{
        position: relative;
        right: -2vw;
    }
}
@media (min-width:990px) {
    footer{
        right: 75px;
    }
}
@media (min-width:1200px) {
    .title{
        font-size: clamp(60px, 4.5svw, 4.5svw); /* clamp(60px, 4.5svw, 88px) */
        line-height: 62px;
    }
}
</style>