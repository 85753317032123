<template>
    <div class="hover-color white" :class="position">
        <div class="d-flex" :class="position">
            <div class="arrow">
                <i class="ri-arrow-left-line"></i>
            </div>
            <div>
                <p class="section">{{ section }}</p>
                <p class="title">{{ title }}</p>
            </div>
        </div>
        <div class="image">
            <picture>
                <source :srcset=srcWebp type="image/webp">
                <source :srcset=srcJpg type="image/jpeg" />
                <source :srcset=srcPng type="image/png">
                <img class="col-11 col-lg-10 col-xl-9 " :src=srcWebp :alt=altImg />
            </picture>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PostNavigation',
    components: {
    },
    props: {
        section: {
            type: Boolean,
        },
        title: {
            type: Boolean,
        },
        srcWebp: {
            type: String,
        },
        srcPng: {
            type: String,
        },
        srcJpg: {
            type: String,
        },
        altImg: {
            type: String,
        },
        position: {
            type: String,
            default: 'before',
        },
    },
}
</script>
<style scoped>
.section{
    margin: 0 1rem 5px 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
}
.title{
    margin: 0 1rem;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
}
.arrow{
    font-size: 4rem;
    padding: 0;
    margin: 0;
    width: auto;
    height: 80px;
    display: flex;
    align-items: center;
}
.arrow i{
    line-height: 80px;
}
.image img{
    width: 80px;
    height: 80px;
    border-radius: 0.5rem;
    overflow: hidden;
    object-fit: cover;
}
.after{
    flex-direction: row-reverse;
}
.before .d-flex, .after .d-flex{
    align-items: center;
}
.after .d-flex{
    flex-direction: row-reverse;
}
.after .arrow{
    transform: rotate(180deg);
}
.hover-color.white{
    transition: all 0.5s ease-in-out;
    will-change: transform;
    cursor: pointer;
    background-color: var(--background);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.hover-color:hover{
    transform: scale(1.02);
}
.hover-color.white:hover{
    background-color: var(--light-violet);
}
.d-flex.after{
    justify-content: space-between;
    width: 100%;
}

/* RESPONSIVE */
@media (max-width:576px) {
    .image img{
        display: none;
    }
}
</style>