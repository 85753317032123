<template>
    <main id="contact-page" class="col-12 col-lg-11 m-auto flex-column">
        <div class="col-12 mx-auto"><!-- col-lg-8 -->
            <SectionIntroTitle 
            sectionName="Contacto"
            alignCenter="true"
            ppalText="Queremos"
            secondText="saber de tí."
            />
        </div>
        <ContactInfo />
    </main> 
    <FooterBar :violetAnimation=false />
</template>

<script>
import ContactInfo from './ContactInfo.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';

export default {
    name: 'ContactPage',
    components: {
        SectionIntroTitle,
        ContactInfo,
        FooterBar,
    },
}
</script>

<style scoped>
</style>