<template>
    <div class="big-button" :class="{[borderRadius]: true, [backColor]: true}">
        <a v-if="targetBlank" :href="link" :target="targetBlank ? '_blank' : '_self'">
            {{text}}
        </a>
        <router-link v-else :to=link :aria-label=title>
            {{text}}
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'ButtonBig',
    props:  {
        text: {
            type: String,
        },
        link: {
            type: String,
        },
        targetBlank: {
            type: Boolean,
            default: false,
        },
        backColor: {
            type: String,
            default: "white",
        },
        borderRadius: {
            type: String,
            default: "null",
        }
    },
}
</script>

<style scoped>
.big-button {
    border-radius: 1rem;
    display: flex;
    transition: all 0.5s ease-in-out;
    will-change: transform;
    cursor: pointer;
    padding: 1.5rem;
    margin-bottom: 1rem;
}
.big-button a {
    text-decoration: none;
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
}
.big-button:hover{
    transform: scale(1.02);
}
.big-button.violet{
    background-color: var(--dark-violet);
}
.big-button.violet:hover{
    background-color: var(--light-violet);
}
.big-button.violet a {
    color: var(--white)!important;
}
.big-button.white{
    background-color: var(--white);
}
.big-button.white a {
    color: var(--dark-violet)!important;
}
.big-button.white:hover{
    background-color: var(--light-violet);
}
.big-button.soft-violet{
    background-color: var(--soft-hover-violet);
    color: var(--dark-violet) !important;
}
.big-button.soft-violet:hover{
    background-color: var(--light-violet);
    color: var(--dark-violet) !important;
}
.big-button.soft-violet a {
    color: var(--white)!important;
    color: var(--dark-violet) !important;
}
.border-rounded{
    border-radius: calc(2.8rem + 1.8rem + 3rem);
    max-width: max-content;
    margin: auto;
    margin-top: 2rem;
}
.border-rounded a{
    margin: 0;
    padding: 1.5rem 2.5rem;
}

@media (max-width:650px) {
    .border-rounded{
        min-width: 100%;
    }
    .border-rounded a{
        padding: 0.8rem; 
        font-size: 2.3rem;
        line-height: 2.3rem;
    }
}
</style>