<template>
    <div id="faq-questions" class="col-12">
        <div class="filters col-12 col-lg-3">
            <ButtonFilterBlock class="button-filter" id="filter-faq-card" text="Todas" @click="filterSection(`faq-card`)"/>
            <template v-for="sections in FAQs" :key="sections.id">
                <ButtonFilterBlock class="button-filter" :id="`filter-${sections.idName}`" :text="sections.section" @click="filterSection(sections.idName)"/>
            </template>
        </div>
        <div class="col-12 col-lg-9">
            <template v-for="sections in FAQs" :key="sections.id">
                <div :class="sections.idName" class="faq-card">
                    <p class="section">{{sections.section}}</p>
                    <AccordionCards :ArrayInfo="sections.questions"/>
                </div>
            </template>
        </div>       
    </div>    
</template>

<script>
import AccordionCards from '@/components/AccordionCards.vue';
import FAQs from './faqs.js';
import ButtonFilterBlock from '@/components/ButtonFilterBlock.vue';

export default {
    name: 'AccordionSection',
    components: {
        AccordionCards,
        ButtonFilterBlock,
    },
    data() {
        return {
            FAQs : FAQs,
        }
    },
    methods: {
        filterSection(sectionName) {
            let buttonsFilter = Array.from(document.getElementsByClassName("button-filter"));

            buttonsFilter.forEach(element => {
                element.classList.remove('selected');
            });
            
            let buttonId = document.getElementById(`filter-${sectionName}`);
            buttonId.classList.add('selected');

            let allNews = Array.from(document.getElementsByClassName("faq-card"));
            allNews.forEach(element => {
                element.style.display="none";
            });
            
            let faqClass =  Array.from(document.getElementsByClassName(sectionName));
            faqClass.forEach(element => {
                element.style.display="flex";
                element.style.flexDirection="column";
            });
        },
    }
}
</script>

<style scoped>
p.section{
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 1.5rem;
}
.filters{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: min-content;
    position: sticky;
    top: 0;
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--white);
    z-index: 4;
}

/* RESPONSIVE */
@media (min-width:992px) {
    #faq-questions{
        display: flex;
        gap: 2rem;
        width: calc(100% - 2rem);
    }
    .filters{
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: left;
        padding-top: 0;
        top: 2rem;
    }
}
</style>



