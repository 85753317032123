<template>
    <main>
        <SectionIntroTitle 
        sectionName="Sobre Nosotros"
        alignCenter="true"
        ppalText="Traumatología y Medicina del Dolor"
        secondText="con base científica."
        />
        <p class="description col-12 col-lg-10 col-xl-7"></p>
        <WhiteBackground backColor="white">
            <div id="carouselNovocel" class="carousel slide image col-11 img-container mx-auto" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <PictureStructure 
                        :srcWebp="ReceptionWebp"
                        :srcWebp320="ReceptionWebp320"
                        :srcWebp480="ReceptionWebp480"
                        :srcWebp768="ReceptionWebp768"
                        :srcWebp1080="ReceptionWebp1080"
                        altImg="Recepción de Novocel"
                        classes="w-100"
                        />
                    </div>
                    <div class="carousel-item">
                        <PictureStructure 
                        :srcWebp="Consulta1Webp"
                        :srcWebp320="Consulta1Webp320"
                        :srcWebp480="Consulta1Webp480"
                        :srcWebp768="Consulta1Webp768"
                        :srcWebp1080="Consulta1Webp1080"
                        altImg="Sala de consulta de Novocel"
                        classes="w-100"
                        />
                    </div>
                    <div class="carousel-item">
                        <PictureStructure 
                        :srcWebp="Consulta2Webp"
                        :srcWebp320="Consulta2Webp320"
                        :srcWebp480="Consulta2Webp480"
                        :srcWebp768="Consulta2Webp768"
                        :srcWebp1080="Consulta2Webp1080"
                        altImg="Sala de consulta de Novocel"
                        classes="w-100"
                        />
                    </div>
                    <div class="carousel-item">
                        <PictureStructure 
                        :srcWebp="EsperaWebp"
                        :srcWebp320="EsperaWebp320"
                        :srcWebp480="EsperaWebp480"
                        :srcWebp768="EsperaWebp768"
                        :srcWebp1080="EsperaWebp1080"
                        altImg="Sala de espera de Novocel"
                        classes="w-100"
                        />
                    </div>
                    <div class="carousel-item">
                        <PictureStructure 
                        :srcWebp="SalaWebp"
                        :srcWebp320="SalaWebp320"
                        :srcWebp480="SalaWebp480"
                        :srcWebp768="SalaWebp768"
                        :srcWebp1080="SalaWebp1080"
                        altImg="Sala de consulta de Novocel"
                        classes="w-100"
                        />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselNovocel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselNovocel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <div class="intro-text">
                <div>
                    <p class="subtitle"><span class="secondText">Una experiencia </span>de salud diferente.</p> 
                </div>
                <div class="text">
                    <p class="bolder">
                        Somos un equipo apasionado de especialistas comprometidos con tu salud. En Clínica Novocel, fusionamos la excelencia médica con la innovación para ofrecerte una experiencia única.
                    </p>
                    <p>
                        Imagina un espacio donde la medicina, ciencia y la tecnología se entrelazan para brindarte soluciones vanguardistas en traumatología, medicina del dolor, cirugía plástica y biotecnología. En Novocel, no solo encontrarás expertos altamente capacitados, sino también profesionales que se preocupan genuinamente por tu bienestar.
                    </p>
                    <p>
                        Nos destacamos por ir más allá de los límites convencionales, abrazando la vanguardia en medicina y ciencia para asegurarnos de que recibas la atención más avanzada y personalizada posible. En Clínica Novocel, tu experiencia es nuestra prioridad número uno, y nuestro compromiso es guiarte para que vuelvas a ser quien quieres ser.
                    </p>
                    <p>
                        ¡Descubre un nuevo estándar en salud!
                    </p>
                </div>
            </div>
            <picture>
                <source :srcset=CurveUpWebp type="image/webp">
                <source :srcset=CurveUpSvg type="image/svg+xml" />
                <source :srcset=CurveUpPng type="image/png">
                <img class="curve" :src=CurveUpWebp alt="Curva decorativa" />
            </picture>
            <OurTeam class="our-team"/>
        </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import OurTeam from './OurTeam.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
import CurveUpSvg from '../../assets/img/decoration/curve-up.svg';
import CurveUpPng from '../../assets/img/decoration/curve-up.png';
import CurveUpWebp from '../../assets/img/decoration/curve-up.webp';
import PictureStructure from '@/components/PictureStructure.vue';

import ReceptionWebp from '@/assets/img/clinica/webp/recepcion.webp';
import ReceptionWebp320 from '@/assets/img/clinica/webp/recepcion_320.webp';
import ReceptionWebp480 from '@/assets/img/clinica/webp/recepcion_480.webp';
import ReceptionWebp768 from '@/assets/img/clinica/webp/recepcion_768.webp';
import ReceptionWebp1080 from '@/assets/img/clinica/webp/recepcion_1080.webp';

import Consulta1Webp from '@/assets/img/clinica/webp/consulta1.webp';
import Consulta1Webp320 from '@/assets/img/clinica/webp/consulta1_320.webp';
import Consulta1Webp480 from '@/assets/img/clinica/webp/consulta1_480.webp';
import Consulta1Webp768 from '@/assets/img/clinica/webp/consulta1_768.webp';
import Consulta1Webp1080 from '@/assets/img/clinica/webp/consulta1_1080.webp';

import Consulta2Webp from '@/assets/img/clinica/webp/consulta2.webp';
import Consulta2Webp320 from '@/assets/img/clinica/webp/consulta2_320.webp';
import Consulta2Webp480 from '@/assets/img/clinica/webp/consulta2_480.webp';
import Consulta2Webp768 from '@/assets/img/clinica/webp/consulta2_768.webp';
import Consulta2Webp1080 from '@/assets/img/clinica/webp/consulta2_1080.webp';

import EsperaWebp from '@/assets/img/clinica/webp/espera.webp';
import EsperaWebp320 from '@/assets/img/clinica/webp/espera_320.webp';
import EsperaWebp480 from '@/assets/img/clinica/webp/espera_480.webp';
import EsperaWebp768 from '@/assets/img/clinica/webp/espera_768.webp';
import EsperaWebp1080 from '@/assets/img/clinica/webp/espera_1080.webp';

import SalaWebp from '@/assets/img/clinica/webp/saladep.webp';
import SalaWebp320 from '@/assets/img/clinica/webp/saladep_320.webp';
import SalaWebp480 from '@/assets/img/clinica/webp/saladep_480.webp';
import SalaWebp768 from '@/assets/img/clinica/webp/saladep_768.webp';
import SalaWebp1080 from '@/assets/img/clinica/webp/saladep_1080.webp';

export default {
    name: 'AboutUsPage',
    components: {
        SectionIntroTitle,
        WhiteBackground,
        OurTeam,
        FooterBar,
        PictureStructure,
    },
    data() {
        return {
            CurveUpSvg, CurveUpPng, CurveUpWebp,            
            ReceptionWebp, ReceptionWebp320, ReceptionWebp480, ReceptionWebp768, ReceptionWebp1080,
            Consulta1Webp, Consulta1Webp320, Consulta1Webp480, Consulta1Webp768, Consulta1Webp1080,
            Consulta2Webp, Consulta2Webp320, Consulta2Webp480, Consulta2Webp768, Consulta2Webp1080,
            EsperaWebp, EsperaWebp320, EsperaWebp480, EsperaWebp768, EsperaWebp1080,
            SalaWebp, SalaWebp320, SalaWebp480, SalaWebp768, SalaWebp1080,
        }
    },
}
</script>

<style scoped>
.img-container{
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
.img-container img{
    height: 400px;
    width: 100%;
    object-fit: cover;
}
.description{
    text-align: center;
    margin: 2rem auto 10rem auto;
    position: relative;
    z-index: 2;
}
.image{
    position: relative;
    top: -7.5rem;
    margin-bottom: -7.5rem;    
}
.image img{
    min-height: 250px;
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
.intro-text{
    padding: 5rem 0;
    position: relative;
    z-index: 2;
}
.subtitle{
    color: var(--light-violet);
    font-size: 40px;
    line-height: 45px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 2rem;
}
.secondText{
    color: var(--dark-violet);
}
.bolder{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.curve{
    position: relative;
    margin-bottom: -580px ;
    top: -580px ;
    max-width: 100%;
}

#carousel-columns{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: auto;    
}
.our-team{
    margin-top: 4rem;
    padding-bottom: 5rem;
}
/* RESPONSIVE */
@media (min-width:576px) {
    .subtitle{
        font-size: 50px;
        line-height: 55px;
        max-width: 80%;
    }
    .text{
        padding-left: 20%;
    }
}

@media (min-width:768px) {
    .curve{
        left: 5%;
    }
    .img-container{
        height: 600px;
    }
    .img-container img{
        height: 600px;
    }
}

@media (min-width:992px) {
    #carousel-columns{
        flex-direction: row;
    }
}

@media (min-width:1200px) {
    .subtitle{
        font-size: 60px;
        line-height: 62px;
        max-width: 50%;
    }
    .text{
        padding-left: 42%;
        padding-top: 0.5rem;
    }
    .curve{
        max-width: 390px;
        margin-bottom: -600px;
        top: -600px;
    }
}
</style>