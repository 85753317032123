import CinthiaJpg from '../../assets/img/team/jpg/Cinthia_100.jpg';
import CinthiaWebp from '../../assets/img/team/webp/Cinthia_100.webp';
import CinthiaPng from '../../assets/img/team/png/Cinthia_100.png';

//import HansJpg from '../../assets/img/team/jpg/Hans_100.jpg';
//import HansPng from '../../assets/img/team/png/Hans_100.png';
//import HansWebp from '../../assets/img/team/webp/Hans_100.webp';

import HumbertoJpg from '../../assets/img/team/jpg/Humberto_100.jpg';
import HumbertoWebp from '../../assets/img/team/webp/Humberto_100.webp';
import HumbertoPng from '../../assets/img/team/png/Humberto_100.png';

import ClaudioJpg from '../../assets/img/team/jpg/Claudio_100.jpg';
import ClaudioWebp from '../../assets/img/team/webp/Claudio_100.webp';
import ClaudioPng from '../../assets/img/team/png/Claudio_100.png';

import LuzJpg from '../../assets/img/team/jpg/Luz_100.jpg';
import LuzWebp from '../../assets/img/team/webp/Luz_100.webp';
import LuzPng from '../../assets/img/team/png/Luz_100.png';

import RolichJpg from '../../assets/img/team/jpg/rolich-100.jpg';
import RolichWebp from '../../assets/img/team/webp/rolich-100.webp';
import RolichPng from '../../assets/img/team/png/rolich-100.png';

let team= [
    {
        idName: 'column-1',
        members: [
            {
                id: 1,
                name: 'Cinthia Briceño Rosas',
                profession: "Directora Científica",
                srcJpg: CinthiaJpg, 
                srcWebp: CinthiaWebp,
                srcPng: CinthiaPng,
                description: `Ingeniera en biotecnología de la Universidad Andrés Bello. 
                Especialista en medicina regenerativa y proyectos de investigación. 
                Ex-miembro del comité científico de la Asociación Chilena de Ingeniería Tisular y Ondas de Choque (ACHITOC). 
                Fundador y director científico de Neobimed y Clínica Novocel.`
            },
            {
                id: 2,
                name: 'Dr. Humberto Verdugo Marchese',
                profession: "Director Médico",
                srcJpg: HumbertoJpg, 
                srcWebp: HumbertoWebp,
                srcPng: HumbertoPng,
                description: `Médico cirujano de la Universidad de Valparaíso, especialista en Traumatología y Ortopedia. 
                Fundador y director médico del centro médico Sports MD y Clínica Novocel.`,
            },
            {
                id: 3,
                name: 'Dr. Héctor Flores',
                profession: "Traumatólogo",
                srcJpg: HumbertoJpg, 
                srcWebp: HumbertoWebp,
                srcPng: HumbertoPng,
                description: `Médico cirujano especialista en traumatología. 
                Experto en extremidades inferiores y oncología ortopédica`,
            },
            {
                id: 4,
                name: 'Dr. Raúl Verdugo.',
                profession: "Médico especialista en dolor crónico",
                srcJpg: ClaudioJpg, 
                srcWebp: ClaudioWebp,
                srcPng: ClaudioPng,
                description: `Ingeniero Civil Electrónico de la PUCV. Especialista en innovación y transformación digital. 
                Socio y director comercial de Kausana y Clínica Novocel.`,
            },
        ]
    },
    {
        idName: 'column-2',
        members: [
            {
                id: 5,
                name: 'Dr. Rolich Escobar',
                profession: "Cirujano Plástico y Estético",
                srcJpg: RolichJpg, 
                srcWebp: RolichWebp,
                srcPng: RolichPng,
                description: `Médico cirujano de la Universidad de Valparaíso y cirujano plástico miembro de importantes sociedades internacionales. 
                Director del área de medicina estética de Clínica Novocel.`,
            },
            {
                id: 6,
                name: 'Ing. Claudio Carreño B.',
                profession: "Director Comercial",
                srcJpg: ClaudioJpg, 
                srcWebp: ClaudioWebp,
                srcPng: ClaudioPng,
                description: `Ingeniero Civil Electrónico de la PUCV. Especialista en innovación y transformación digital. 
                Socio y director comercial de Kausana y Clínica Novocel.`,
            },
            {
                id: 7,
                name: 'Luz María Villarroel',
                profession: "Anfitriona y secretaria",
                srcJpg: LuzJpg, 
                srcWebp: LuzWebp,
                srcPng: LuzPng,
                description: `Anfitriona y recepcionista de Clínica Novocel. 
                Atención al cliente, responsable de la primera experiencia del paciente en Clínica Novocel.`,
            },
            {
                id: 8,
                name: 'Dr. Fernando Tapia',
                profession: "Médico especialista en dolor crónico",
                srcJpg: RolichJpg, 
                srcWebp: RolichWebp,
                srcPng: RolichPng,
                description: `Médico cirujano de la Universidad de Valparaíso y cirujano plástico miembro de importantes sociedades internacionales. 
                Director del área de medicina estética de Clínica Novocel.`,
            },
            {
                id: 9,
                name: 'Dra. Liliana Hernández',
                profession: "Médico general",
                srcJpg: LuzJpg, 
                srcWebp: LuzWebp,
                srcPng: LuzPng,
                description: `Anfitriona y recepcionista de Clínica Novocel. 
                Atención al cliente, responsable de la primera experiencia del paciente en Clínica Novocel.`,
            },
        ]
    },

]

export default team;