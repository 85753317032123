<template>
    <div class="filter-name" :class="{ 'm-auto': alignCenter }">
        <p>
            {{text}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'ButtonFilter',
    props: {
        text: {
            type: String,
        },
        alignCenter: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.filter-name{
    padding: 5px;
}
.filter-name p {
    border: 1px solid var(--dark-violet);
    border-radius: 42px;
    width: fit-content;
    margin: 0;
    padding: 0.4rem 1.4rem;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
}
.filter-name p:hover {
    background-color: var(--dark-violet);
    color: var(--white);
}
.selected p{
    background-color: var(--dark-violet);
    color: var(--white);
}
</style>