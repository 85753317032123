<template>
    <div id="pathology-section" class="col-12">
        <div class="filters col-12 col-lg-3">
            <template v-for="sections in Pathology" :key="sections.id">
                <ButtonFilterImg class="button-filter" :id="`filter-${sections.idName}`" :text="sections.section" :srcSvg=sections.srcSvg :srcPng=sections.srcPng :srcWebp=sections.srcWebp @click="filterSection(sections.idName)"/>
            </template>
        </div>
        <div class="col-12 col-lg-9">
            <template v-for="sections in Pathology" :key="sections.id">
                <div :class="sections.idName" class="pathology-card">
                    <p class="section">{{sections.section}}</p>
                    <p class="highlighted-text">{{ sections.highlightedText }}</p>
                    
                    <span v-for="(line, index) in (sections.firstTextPost).split('\n')" :key="index">
                        {{ line }}<br v-if="index < (sections.firstTextPost).split('\n').length - 1">
                    </span>

                    <div class="image">
                        <picture>
                            <source :srcset=sections.postImgWebp type="image/webp">
                            <source :srcset=sections.postImgJpg type="image/jpeg" />
                            <source :srcset=sections.postImgPng type="image/png">
                            <img class="col-12" :src=sections.postImgWebp alt="Dolor de columna" />
                        </picture>
                    </div>

                    <span v-for="(line, index) in (sections.secondTextPost).split('\n')" :key="index">
                        {{ line }}<br v-if="index < (sections.secondTextPost).split('\n').length - 1">
                    </span>
                </div>
            </template>
        </div>       
    </div> 

</template>

<script>
import ButtonFilterImg from '@/components/ButtonFilterImg.vue';
import Pathology from './pathology.js';

export default {
    name: 'PathologySection',
    components: {
        ButtonFilterImg,
    },
    data() {
        return {
            Pathology,
        }
    },
    mounted() {
        let allNews = Array.from(document.getElementsByClassName("pathology-card"));
        allNews.forEach(element => {
            element.style.display="none";
        });
        allNews[0].style.display="inherit";
        let buttonsFilter = Array.from(document.getElementsByClassName("button-filter"));
        buttonsFilter[0].classList.add('selected');
    },
    methods: {
        filterSection(sectionName) {
            let buttonsFilter = Array.from(document.getElementsByClassName("button-filter"));
            buttonsFilter.forEach(element => {
                element.classList.remove('selected');
            });
            
            let buttonId = document.getElementById(`filter-${sectionName}`);
            buttonId.classList.add('selected');

            let allNews = Array.from(document.getElementsByClassName("pathology-card"));
            allNews.forEach(element => {
                element.style.display="none";
            });
            
            let pathologyClass =  Array.from(document.getElementsByClassName(sectionName));
            pathologyClass.forEach(element => {
                element.style.display="flex";
                element.style.flexDirection="column";
            });
        },
    }
}
</script>

<style scoped>
p.section{
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 1.4rem;
    margin-top: 0.5rem;
}
.filters{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: min-content;
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--white);
}
.image img{
    max-height: 450px;
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
    margin: 2rem 0;
}
#pathology-section{
    margin-top: 4.5rem;
    padding-bottom: 5.5rem;
}
.highlighted-text{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.5px;
}
.combined-therapies .image img{ 
    border: var(--background) solid 1px;
}
/* RESPONSIVE */
@media (min-width:992px) {
    #pathology-section{
        display: flex;
        gap: 2rem;
        width: calc(100% - 2rem);
    }
    .filters{
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: left;
        padding-top: 0;
        top: 2rem;
        position: sticky;
        z-index: 4;
    }
    p.section{
        font-size: 40px;
        line-height: 45px;
    }
    .col-lg-9{
        padding-right: 4.3%;
    }
}
</style>