<template>
    <section id="blog-text">
        <div class="post-subtitle col-11 col-lg-10 col-xl-9" v-if="postSubtitle">
            {{ postSubtitle }}
        </div>
        <div class="post-text col-11 col-lg-9 col-xl-7">
            <span v-for="(line, index) in postText.split('\n')" :key="index">
                {{ line }}<br v-if="index < postText.split('\n').length - 1">
            </span>
        </div>
        <div class="post-author">
            {{ postDate }} por {{ postAuthor }}
        </div>
    </section>
</template>

<script>
export default {
    name: 'BlogText',
    props:  {
        postSubtitle: {
            type: String,
        },
        postText: {
            type: String,
        },
        postDate: {
            type: String,
        },
        postAuthor: {
            type: String,
        },
    },
}
</script>


<style scoped>
.post-subtitle{
    border-radius: 1rem;
    background-color: var(--background);
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
}
.post-text{
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.35rem;
    margin-bottom: 1rem;
}
#blog-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
}
.post-author{
    padding: 2rem 0;
    text-align: center;
}
</style>