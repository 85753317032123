<template>
    <main>
        <SectionIntroTitle 
        sectionName="Preguntas frecuentes"
        alignCenter="true"
        ppalText="¿Tiene preguntas?"
        secondText="Tenemos respuestas."
        />
        <WhiteBackground backColor="white">
            <section id="faq-info">                
                <AccordionSection />
            </section>
            <div class="contact">
                <TitleSecodary ppalText="¿Alguna duda o consulta?" />
                <ButtonBig class="center" text="Contáctanos" link="/contacto" backColor="soft-violet" borderRadius="border-rounded" />
            </div>
        </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import AccordionSection from './AccordionSection.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import ButtonBig from '@/components/ButtonBig.vue';

export default {
    name: 'FAQsPage',
    components: {
        SectionIntroTitle,
        WhiteBackground,
        AccordionSection,
        FooterBar,
        TitleSecodary,
        ButtonBig,
    },
}
</script>

<style scoped>
#faq-info{
    margin-top: 3.5rem;
    padding: 3rem 0;
}
.contact{
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
}
.center{
    justify-content: center;
}
</style>