<template>
    <div class="back"></div>
    <main>        
        <WhiteBackground backColor="white">
            <BlogPostTitle 
            :sectionName=post.section
            alignCenter="true"
            :ppalText=post.title
            :srcWebp="post.srcWebp"
            :srcPng="post.srcPng"
            :srcJpg="post.srcJpg"
            :altImg="post.altImg"
            /> 

            <BlogText
            :postSubtitle="post.subtitle"
            :postText="post.fullText"
            :postDate="post.date"
            :postAuthor="post.author"
            />
            
            <div class="navigation-content">
                <router-link :to="goToPreviousPage(post.id)" :aria-label=post.title class="link col-12 col-lg-6">
                    <PostNavigation
                    position="before"
                    :section="previousPost.section"
                    :title="previousPost.title"
                    :srcWebp="previousPost.srcWebp"
                    :srcPng="previousPost.srcPng"
                    :srcJpg="previousPost.srcJpg"
                    :altImg="previousPost.altImg"
                    />
                </router-link>

                <router-link :to="goToNextPage(post.id)" :aria-label=post.title class="link col-12 col-lg-6">
                    <PostNavigation
                    position="after"
                    :section="nextPost.section"
                    :title="nextPost.title"
                    :srcWebp="nextPost.srcWebp"
                    :srcPng="nextPost.srcPng"
                    :srcJpg="nextPost.srcJpg"
                    :altImg="nextPost.altImg"
                    />
                </router-link>
            </div>
        </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import FooterBar from '../BasicComponents/FooterBar.vue';
import BlogPostTitle from './BlogPostTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import BlogText from './BlogText.vue';
import PostNavigation from './PostNavigation.vue';
import BlogNews from './blogNews.js'

export default {
    name: 'BlogPost',
    components: {
        FooterBar,
        BlogPostTitle,
        WhiteBackground,
        BlogText,
        PostNavigation,
    },
    props: ['id'],
    data() {
        return {
            BlogNews : BlogNews,
            post: null,
            formatedText: null,
        }
    },
    watch: {
        '$route.params.id'(newId) {
        this.post = BlogNews.find((post) => post.id == newId);
        },
    },
    created() {
        const id = this.$route.params.id;
        this.post = BlogNews.find((post) => post.id == id);
    },
    computed: {
        previousPostIndex() {
            const currentIndex = this.BlogNews.findIndex((post) => post.id == this.post.id);
            return currentIndex > 0 ? currentIndex - 1 : this.BlogNews.length - 1;
        },
        previousPost() {
            return this.BlogNews[this.previousPostIndex];
        },
        nextPostIndex() {
            const currentIndex = this.BlogNews.findIndex((post) => post.id == this.post.id);
            return currentIndex >= this.BlogNews.length - 1 ? 0 : currentIndex + 1;
        },
        nextPost() {
            return this.BlogNews[this.nextPostIndex];
        },
    },
    methods: {
        goToPreviousPage(id) {
            const lastPostId = this.BlogNews[this.BlogNews.length - 1].id;
            if (id <= 1) {
                return `/blog/post/${lastPostId}`;
            } else {
                return `/blog/post/${id - 1}`;
            }
        },
        goToNextPage(id) {           
            const lastPostId = this.BlogNews[this.BlogNews.length - 1].id;
            if (id >= lastPostId) {
                return '/blog/post/1';
            } else {
                return `/blog/post/${id + 1}`;
            }
        },
    }
}
</script>

<style scoped>
.link{
    text-decoration: none;
}
#blog-post{
    margin-top: 3.5rem;
    padding: 3rem 0;
}
div.back{
    background-color: var(--white);
    position: fixed;
    z-index: -1;
    height: 165px;
    width: 100vw;
    top: 0;
    left: 0;
}
.navigation-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 4rem;
}
.col-12{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .col-lg-6 {
        width: calc(50% - 10px);
    }
}
</style>