<template>
    <!-- <router-link :to="to" class="no-decoration"> -->
        <div class="card " :class="{'soft-violet':background}">
            <div>
                <img :src=icon :alt=altIcon />
            </div>
            <div>
                <p class="title">{{ title }}</p>
                <div class="description">
                    <slot name="description"></slot>
                </div>
            </div>
        </div>
    <!-- </router-link> -->
</template>

<script>
export default {
    name: 'CardProcedure',
    components: {
    },
    props:  {
        icon: {
            type: String,
        },
        altIcon: {
            type: String,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        background: {
            type: Boolean,
            default: false,
        },
        /* to: {
            type: String,
        } */
    },
}
</script>

<style scoped>
.no-decoration{
    text-decoration: none;
}
.card{
    border-radius: 1rem;
    background-color: var(--white);
    border: none;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    min-height: 250px;
}
.card.soft-violet{
    background-color: var(--soft-hover-violet);
}
.title{
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    /* hyphens: auto;
    word-wrap: break-word; */
}
/* RESPONSIVE */
@media (min-width:575px) {
    .title{
        font-size: 3rem;
        line-height: 2.9rem;
    }
    .card{
        min-height: 350px;
    }
}
</style>