<template>
    <main>
        <SectionIntroTitle 
        sectionName="Valores"
        alignCenter="true"
        ppalText="Transparentamos nuestros valores"
        secondText="para que puedas tomar una decisión informada."
        />
        <p class="description col-12 col-lg-10 col-xl-7"></p>
        <WhiteBackground id="prices" backColor="white" >
            <div class="curve-container">
                <picture>
                    <source :srcset=CurveUpWebp type="image/webp">
                    <source :srcset=CurveUpSvg type="image/svg+xml" />
                    <source :srcset=CurveUpPng type="image/png">
                    <img class="curve" :src=TraumatologyWebp alt="Curva decorativa" />
                </picture>
            </div>
            <div class="cards-container d-flex flex-wrap">           
                <template v-for="item in PricesItems" :key="item.id">
                    <CardPrice
                    class="col-12"
                    :title="item.title"
                    :price="item.price"
                    :time="item.time"
                    :explanation="item.explanation"
                    :items="item.items"
                    :backgroundColor="item.backgroundColor" 
                    :responsiveWidth="item.responsiveWidth"
                    />
                </template>
            </div>
        </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
import CardPrice from '@/components/CardPrice.vue';
import PricesItems from './prices.js';

import CurveUpSvg from '../../assets/img/decoration/curve-up.svg';
import CurveUpPng from '../../assets/img/decoration/curve-up.png';
import CurveUpWebp from '../../assets/img/decoration/curve-up.webp';

export default {
    name: 'PricesPage',
    components: {
        SectionIntroTitle,
        WhiteBackground,
        FooterBar,
        CardPrice,
    },
    data() {
        return {
            PricesItems : PricesItems,
            CurveUpSvg : CurveUpSvg,
            CurveUpWebp : CurveUpWebp,
            CurveUpPng : CurveUpPng,
        }
    },
}
</script>

<style scoped>
.description{
    text-align: center;
    margin: 2rem auto 6rem auto;
    position: relative;
    z-index: 2;
}
#prices{
    padding-top: 5rem;
    position: relative;
    z-index: 1;
}
.curve-container{
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    position: relative;
}
.curve{
    margin-bottom: -580px ;
    top: -580px;
    max-width: 100%;
    position: relative;
    opacity: 0;
}
.cards-container{
    position: relative;
    bottom: 150px;
}

/* RESPONSIVE */
@media (min-width:768px) {
    .curve-container{
        justify-content: left;
        bottom: -250px;
    }
    .cards-container{
        justify-content: flex-start;
    }
    .curve{
        opacity: 1;
    }
}
@media (min-width:1200px) {
    .curve{
        left: -5%;
        max-width: 390px;
    }
    #prices{
        padding-top: 0;
        padding-top: 200px;
    }
    .cards-container{
        top: -150px;
        padding: 0 10px;
    }
}
</style>