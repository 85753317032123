/* IMAGES */
/* ARTROSIS */
import ArtrosisSvg from '@/assets/img/icons/svg/artrosis.svg'
import ArtrosisPng from '@/assets/img/icons/png/artrosis.png'
import ArtrosisWebp from '@/assets/img/icons/webp/artrosis.webp'
import MujerJpg from '@/assets/img/photos/jpg/artro.jpg'
import MujerPng from '@/assets/img/photos/png/artro.png'
import MujerWebp from '@/assets/img/photos/webp/artro.webp'

/* CONDROPATIA */
import CondroSvg from '@/assets/img/icons/svg/condromalacia.svg'
import CondroPng from '@/assets/img/icons/png/condromalacia.png'
import CondroWebp from '@/assets/img/icons/webp/condromalacia.webp'
import RodillaJpg from '@/assets/img/photos/jpg/chondro.jpg'
import RodillaPng from '@/assets/img/photos/png/chondro.png'
import RodillaWebp from '@/assets/img/photos/webp/chondro.webp'

/* LESIONES */
import LesionesSvg from '@/assets/img/icons/svg/lesiones.svg'
import LesionesPng from '@/assets/img/icons/png/lesiones.png'
import LesionesWebp from '@/assets/img/icons/webp/lesiones.webp'
import RunnerJpg from '@/assets/img/photos/jpg/deport.jpg'
import RunnerPng from '@/assets/img/photos/png/deport.png'
import RunnerWebp from '@/assets/img/photos/webp/deport.webp'


/* PATHOLOGY INFO */

let pathologyNotes = [
    {
        idName: 'osteoarthritis',
        section: 'Artrosis',
        srcWebp: ArtrosisWebp,
        srcPng: ArtrosisPng,
        srcSvg: ArtrosisSvg,
        altImg: "Ícono de huesos",
        highlightedText: 'En Clínica Novocel realizamos seguimiento de nuestros pacientes por hasta 1 año. El tratamiento combinado de células madre y ácido hialurónico ha tenido una efectividad de más del 90%, tratamiento que ha sido administrado a más de 100 pacientes en el último año.',
        firstTextPost: `La artrosis es una enfermedad degenerativa de las articulaciones que se caracteriza por el desgaste del cartílago y la aparición de osteofitos (proyecciones óseas) en los huesos que conforman la articulación. Esto puede causar dolor, rigidez y limitación del movimiento en la articulación afectada. La artrosis es una de las causas más comunes de discapacidad en personas mayores. \n
            La artrosis afecta principalmente a personas mayores, especialmente a aquellas que tienen más de 65 años. Sin embargo, también puede afectar a personas más jóvenes debido a lesiones en las articulaciones, sobrepeso, trastornos metabólicos, enfermedades genéticas, y otras condiciones que pueden aumentar el riesgo de desarrollar artrosis. Además, ciertas profesiones que implican movimientos repetitivos, desgaste en las articulaciones o sobrecarga pueden aumentar el riesgo de desarrollar artrosis.
        `,
        secondTextPost: `
            En Chile, la artrosis es una de las principales causas de discapacidad en personas mayores y se estima que aproximadamente el 30% de la población de 65 años o más sufre de esta enfermedad. La artrosis es más común en las articulaciones de las rodillas, las caderas y las manos, y es una de las principales causas de reemplazo de articulaciones en el país. El tratamiento de la artrosis en Chile se basa en el alivio del dolor, la mejora de la movilidad y la prevención de la progresión de la enfermedad. El tratamiento incluye fisioterapia, ejercicios de fortalecimiento, medicamentos antiinflamatorios y analgésicos, infiltraciones, y en casos avanzados, cirugía. Sin embargo, también es importante enfatizar en la prevención de la artrosis, mediante una dieta saludable, ejercicio regular, evitar el sobrepeso y mantener un buen estado físico. \n
            Las células madre pueden ayudar en el tratamiento de artrosis de varias maneras: \n
            a) Regeneración del cartílago: Las células madre pueden diferenciarse en células del cartílago y, por lo tanto, pueden ayudar a reparar el daño del cartílago en la rodilla. Esto podría mejorar los síntomas de artrosis y retrasar la necesidad de una cirugía. \n
            b) Inhibir la inflamación: Las células madre pueden producir sustancias que inhiben la inflamación en la rodilla, lo que podría ayudar a aliviar el dolor y la hinchazón. \n
            c) Modulación del sistema inmunológico: las células madre pueden modificar la respuesta inmune del cuerpo lo que puede ayudar a reducir la inflamación en la rodilla. \n
            d) Modulación de la senescencia celular: Las células madre pueden tener propiedades anti-senescencia, lo que podría retrasar el deterioro del cartílago y ayudar a prevenir la artrosis.
        `,
        postImgWebp: MujerWebp,
        postImgJpg: MujerJpg,
        postImgPng: MujerPng,
    },
    {
        idName: 'chondropathy',
        section: 'Condropatía',
        srcWebp: CondroWebp,
        srcPng: CondroPng,
        srcSvg: CondroSvg,
        altImg: "Ícono de huesos en rodilla",
        highlightedText: `Las células madre tienen el potencial de ayudar en el tratamiento de la condropatía.`,
        firstTextPost: `La condropatía es un término que se utiliza para describir una condición en la que el cartílago que recubre la rótula (rodilla) se vuelve delgado y blando. Esto puede ocurrir debido a un desgaste natural del cartílago con el tiempo o como resultado de una lesión. La condropatía también se conoce como "rodilla del corredor" debido a que es común en personas que practican deportes de carrera.`,
        secondTextPost: `
            Los síntomas de la condropatía incluyen dolor en la parte delantera de la rodilla, especialmente cuando se suben condropatía o se sienta con las rodillas cruzadas. También se puede sentir chasquido o crujido en la rodilla y puede haber dificultad para extender completamente la pierna. \n
            El tratamiento de la condropatía puede incluir: fisioterapia para fortalecer los músculos de la rodilla y mejorar la movilida, medicamentos para controlar el dolor y la inflamación, infiltraciones, y cirugía en casos graves. Las células madre pueden ayudar en el tratamiento de la condropatía de varias maneras: \n
            a) Regeneración del cartílago: Las células madre pueden diferenciarse en células del cartílago y, por lo tanto, pueden ayudar a reparar el daño del cartílago en la rodilla. Esto podría mejorar los síntomas de la condropatía y retrasar la necesidad de una cirugía. \n
            b) Inhibir la inflamación: Las células madre pueden producir sustancias que inhiben la inflamación en la rodilla, lo que podría ayudar a aliviar el dolor y la hinchazón. \n
            c) Modulación del sistema inmunológico: las células madre pueden modificar la respuesta inmune del cuerpo lo que puede ayudar a reducir la inflamación en la rodilla. \n
            d) Modulación de la senescencia celular: Las células madre pueden tener propiedades anti-senescencia, lo que podría retrasar el deterioro del cartílago y ayudar a prevenir la condropatía. \n
            La prevención de la condropatía incluye evitar sobrecargar la rodilla, fortalecer los músculos de la pierna y usar calzado adecuado para el deporte que se practique.
        `, 
        postImgWebp: RodillaWebp,
        postImgJpg: RodillaJpg,
        postImgPng: RodillaPng,
    },
    {
        idName: 'sports-injuries',
        section: 'Lesiones deportivas',
        srcWebp: LesionesWebp,
        srcPng: LesionesPng,
        srcSvg: LesionesSvg,
        altImg: "Ícono de rodilla",
        highlightedText: `Entre las lesiones deportivas que tratamos eficazmente está: fascitis plantar, epicondilitis, desgarro del manguito rotador o lesión del ligamento cruzado anterior.`,
        firstTextPost: `Las lesiones deportivas son aquellas que ocurren durante el ejercicio físico, sin importar si es una práctica competitiva o recreacional.`,
        secondTextPost: `Cuando practicas deporte no importando cuál sea, siempre estarás expuesto a sufrir lesiones, las que pueden ser causadas por un evento traumático, como una caída o un golpe, por un uso excesivo o un sobreesfuerzo repetitivo. Es importante, que al tratar con una lesión de este tipo se realice una prevención eficaz, un diagnóstico precoz, y se implemente un tratamiento avanzado que nos permita un reintegro deportivo en el menor tiempo posible y en la más óptima condición. \n
        No todos las lesiones o enfermedades requieren entrar a pabellón, muchas las podemos tratar de manera ambulatoria en sala de procedimientos. El primer paso siempre será la evaluación y de ser necesario, solicitar estudios complementarios para poder determinar el mejor y más adecuado tratamiento según el caso.`,
        postImgWebp: RunnerWebp,
        postImgJpg: RunnerJpg,
        postImgPng: RunnerPng,
    },
    /* {
        section: '',
        questions: [
                id: 1,
                question: "¿Qué es ArtroStem?",
                description: 
        ],
    },    */    
]

export default pathologyNotes;