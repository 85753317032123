<template>
  <main>
    <!-- <SectionPresentation /> -->
    <SectionAnimatedPresentation />
    <div class="second-content">
      <SectionClinic />
      <SectionTreatments />
      <SectionAppointment />
      <SectionPathologies />
      <SectionTeam />
      <SectionContact />
      <SectionBlog />
    </div>
  </main>
  <FooterBar class="mt-6"/>
</template>

<script>
/* import SectionPresentation from './SectionPresentation.vue'; */
import SectionAnimatedPresentation from './SectionAnimatedPresentation.vue';
import SectionClinic from './SectionClinic.vue';
import SectionTreatments from './SectionTreatments.vue';
import SectionAppointment from './SectionAppointment.vue';
import SectionPathologies from './SectionPathologies.vue';
import SectionTeam from './SectionTeam.vue';
import SectionContact from './SectionContact.vue';
import SectionBlog from './SectionBlog.vue';
import FooterBar from '../BasicComponents/FooterBar.vue'

export default {
  name: 'HomePage',
  components: {
    /* SectionPresentation, */
    SectionAnimatedPresentation,
    SectionClinic,
    SectionTreatments,
    SectionAppointment,
    SectionPathologies,
    SectionTeam,
    SectionContact,
    SectionBlog,
    FooterBar
},
}
</script>

<style scoped>
.second-content{
  margin-top: 100svh;
}
</style>
