<template>
    <router-link :to="to" class="no-decoration">
        <div class="card">
            <div>
                <p class="pathologie">{{ pathologie }}</p>
            </div>
            <div>
                <p class="number">{{ number }}</p>
                <p class="description-number">{{ descriptionNumber }}</p>
                <p class="description"> {{ descriptionText }}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'CardPathologies',
    components: {
    },
    props:  {
        pathologie: {
            type: String,
        },
        number: {
            type: String,
        },
        descriptionNumber: {
            type: String,
        },
        descriptionText: {
            type: String,
        },
        to: {
            type: String,
        }
    },
}
</script>

<style scoped>
.no-decoration{
    text-decoration: none;
}
.card{
    border-radius: 1rem;
    background-color: var(--white);
    border: none;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    /* min-height: 450px; */
    background: var(--card-gradient);
}
.pathologie{
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    
}
p.pathologie{
    padding-bottom: 2.5rem;
}
.number{
    font-weight: 700;
    font-size: 75px;
    line-height: 45px;
}
.description-number{
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
}
.description{
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.3rem;
    margin-bottom: 0;
}
/* RESPONSIVE */
@media (max-width: 992px) {
    .card{
        height: auto;
    }
}
@media (min-width: 992px) {
    .card{
        min-height: 450px;
    }
}
@media (min-width:500px) {
    .description-number{
        font-size: 2.7rem;
        line-height: 2.9rem;
    }
}
</style>