<template>
    <div class="contact-info">
        <div class="icons-info">
            <ButtonContact text="contacto@clinicanovocel.cl" iRemix="ri-mail-send-line" />
            <ButtonContact text="+56930580356" iRemix="ri-smartphone-line" />
            <ButtonContact text="12 Norte #785, of. 1003, Viña del Mar" iRemix="ri-map-pin-2-line"/>
            <ButtonContact socialMedia="true" :arrayIremix=arrayIremix />
            <div class="contact-map mobile col-12 col-lg-4" v-if="isMobile">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11253.140956284366!2d-71.55305115336515!3d-33.01487787290363!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689ddc299adf807%3A0xd7416786df538665!2s12%20Nte.%20785%2C%20oficina%201003%2C%202531172%20Valpara%C3%ADso%2C%20Vi%C3%B1a%20del%20Mar%2C%20Valpara%C3%ADso%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1722631650644!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div class="contact-form">
            <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6rG1uq6n62kITC2hiD9uQQ1WtPlgIThiEkLrlvASv0aSeWcl3YTTOG8ysG5fLyzEe7">
            </div>
        </div>
        <div class="contact-map col-12 col-lg-4" v-if="!isMobile">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11253.140956284366!2d-71.55305115336515!3d-33.01487787290363!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689ddc299adf807%3A0xd7416786df538665!2s12%20Nte.%20785%2C%20oficina%201003%2C%202531172%20Valpara%C3%ADso%2C%20Vi%C3%B1a%20del%20Mar%2C%20Valpara%C3%ADso%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1722631650644!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
import ButtonContact from '@/components/ButtonContact.vue';

export default {
    name: 'ContactPage',
    components: {
        ButtonContact,
    },
    props:  {
    },
    data() {
        return {
            isMobile: window.innerWidth < 1500,
            arrayIremix: [
                {
                    icon:  "ri-facebook-circle-line",
                    link: "https://www.facebook.com/clinicanovocel/",
                    ariaLabel: "Facebook",
                },
                {
                    icon:  "ri-instagram-line",
                    link: "https://www.instagram.com/clinicanovocel/",
                    ariaLabel: "Instagram",
                },
                {
                    icon:  "ri-whatsapp-line",
                    link: "https://api.whatsapp.com/send?phone=56930580356&amp;data=AWAGJwnFvK4om90m0t6Q4V1al7eEm1Sa6FWHQv7FEhJSlWPI1IZxXYGuieGZdGt8FDD1fpA4NevxHr-Dmsl0JbQdjCsOecWgsC4en3tMprCxAb9XDaMEEjS8RHqJCPmj4VMKvHsFXQ-q0j9BGHtFhjo-6B7Mc9XnN283N_8_7qawUlbH2ybdqg2TOOMPEvtay_QnL7CKvy6SNYktfzM72iPh7jrYcr9FAc0tbCc-s1ZvAPfw8GLlsDFNsnXMAqnwanltxoKR4DscUAntiBA&amp;source=FB_Page&amp;app=facebook&amp;entry_point=page_cta&amp;fbclid=IwAR1eipXHz7fZEiAA6gV-DPF2cXy34x4YZyvpBPZPuLrFdvBszBXriiiMc2I",
                    ariaLabel: "WhatsApp",
                },
            ]
        }
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth < 1500;
        }
    },
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://webforms.pipedrive.com/f/loader';
        document.head.appendChild(script);
        
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>
.contact-form{
    width: 100%;
}
.icons-info{
    width: 100%;
}
.contact-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    position: relative;
    z-index: 4;
    margin: 4rem 0;
    width: calc(100% - 1rem);
}
.contact-form{
    background-color: var(--white);
    border-radius: 1rem;
    padding: 20px;
    margin-bottom: 1rem;
    min-height: 450px;
}
.contact-map{
    background-color: var(--white);
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
    height: 400px;
    max-height: 80svh;
}
.contact-map.mobile{
    width: 100%;
}
.contact-map iframe{
    width: 100%;
    height: 400px;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .contact-form{
        width: 550px;
    }
    .icons-info{
        width: 385px;
    }
    .contact-info{
        flex-direction: row;
        justify-content: center;
    }
    .contact-map, .contact-map iframe{
        height: 675px;
    }
    .contact-map.mobile, .contact-map.mobile iframe{
        height: 240px;
    }
}
</style>