import { createRouter, createWebHistory } from "vue-router";
import Home from '@/views/HomePage/HomePage.vue';
import TraumatologyPage from '@/views/TraumatologyPage/TraumatologyPage.vue';
import KinesiologyPage from '@/views/KinesiologyPage/KinesiologyPage.vue';
import TreatmentsPage from '@/views/TreatmentsPage/TreatmentsPage.vue';
import PathologyPage from '@/views/PathologyPage/PathologyPage.vue';
import FAQsPage from '@/views/FAQsPage/FAQsPage.vue';
import BlogPage from '@/views/BlogPage/BlogPage.vue';
import AboutUsPage from '@/views/AboutUsPage/AboutUsPage.vue';
import ContactPage from '@/views/ContactPage/ContactPage.vue';
import BlogPost from "@/views/BlogPage/BlogPost.vue";
import PricesPage from "@/views/PricesPage/PricesPage.vue";

const routes = [
    { path: '/', component: Home },
    { path: '/traumatologia', component: TraumatologyPage },
    { path: '/kinesiologia', component: KinesiologyPage },
    { path: '/tratamientos', component: TreatmentsPage },
    { path: '/patologias', component: PathologyPage },
    { path: '/faqs', component: FAQsPage },
    { path: '/blog', component: BlogPage },
    { path: '/blog/post/:id', component: BlogPost },
    { path: '/nosotros', component: AboutUsPage },
    { path: '/contacto', component: ContactPage },
    { path: '/valores', component: PricesPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;