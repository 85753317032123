<template>
    <main>
        <SectionIntroTitle 
        sectionName="Patologías"
        alignCenter="true"
        ppalText="Patologías y lesiones tratadas"
        secondText="validadas científicamente."
        />
        <p class="description col-12 col-lg-10 col-xl-7"></p>
        <WhiteBackground backColor="white">
            <div class="image">
                <picture>
                    <source :srcset=TreatmentsWebp type="image/webp">
                    <source :srcset=TreatmentsJpg type="image/jpeg" />
                    <source :srcset=TreatmentsPng type="image/png">
                    <img class="col-11" :src=TreatmentsWebp alt="Dolor de columna" />
                </picture>
            </div>

            <PathologySection />

            </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
import PathologySection from './PathologySection.vue';

import TreatmentsPng from '../../assets/img/photos/png/treatments.png';
import TreatmentsJpg from '../../assets/img/photos/jpg/treatments.jpg';
import TreatmentsWebp from '../../assets/img/photos/webp/treatments.webp';

export default {
    name: 'PathologyPage',
    components: {
        SectionIntroTitle,
        PathologySection,
        WhiteBackground,
        FooterBar,
    },
    props:  {
    },
    data() {
        return {
            TreatmentsPng,
            TreatmentsJpg,
            TreatmentsWebp,
        }
    },
}
</script>

<style scoped>
.description{
    text-align: center;
    margin: 2rem auto 10rem auto;
    position: relative;
    z-index: 2;
}
.image{
    position: relative;
    top: -7.5rem;
    margin-bottom: -7.5rem;    
}
.image img{
    min-height: 250px;
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
</style>