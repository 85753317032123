<template>
    <div class="section" :class="{'active' : active}">
        <div class="ref-section" @click="toggleActive()">
            <div>
                <p class="ref-number">{{idNumber}}/</p>
            </div>
            <div class="ref-text">
                <p class="pathology">{{pathology}}</p>
            </div>
        </div>
        <div class="ref-information">
            <div class="info-section">
                <div class="text">
                    <h3 class="title">{{pathology}}</h3>
                    <p>{{description}}</p>
                    <div class="click-area">
                        <router-link :to="link" class="no-decoration">
                            <p class="read">
                                Leer más 
                                <i class="ri-arrow-right-s-line"></i>
                            </p>
                        </router-link>
                    </div>
                </div>
                <div class="image">
                    <picture>
                        <source :srcset=srcWebp type="image/webp" />
                        <source :srcset=srcJpg type="image/jpeg" />
                        <source :srcset=srcPng type="image/png" />
                        <img :src=srcWebp :alt=altImg />
                    </picture>
                </div>
            </div>
            <div class="navigation">
                <div @click="showBefore()" class="click-area">
                    <p v-if="id > 1" class="before">
                        <i class="ri-arrow-left-s-line"></i>
                        Anterior
                    </p>
                </div>
                <div @click="showNext()" class="click-area">
                    <p v-if="id < 3" class="next">
                        Siguiente 
                        <i class="ri-arrow-right-s-line"></i>
                    </p>
                </div>
            </div>
        </div>          
    </div>
</template>

<script>
export default {
    name: 'CarouselColum',
    components: {
    },
    props:  {
        id: {
            type: Number,
        },
        pathology: {
            type: String,
        },
        description: {
            type: String,
        },
        srcWebp: {
            type: String,
        },
        srcPng: {
            type: String,
        },
        srcJpg: {
            type: String,
        },
        altImg: {
            type: String,
        },
        active: {
            type: Boolean,
        },
        pathologyItems: Array,
        link: {
            type: String,
        },
    },
    computed: {
        idNumber(){
            if(this.id <= 9){
                return '0' + this.id;
            } else{
                return this.id;
            }
        },
    },
    methods: {
        toggleActive() {
            this.$emit("cardClick", this);
        },
        showBefore() {
            this.$emit("beforeClick", this);
        },
        showNext() {
            this.$emit("nextClick", this);
        },
    },
}
</script>

<style scoped>
.section{
    border-radius: 1rem;
    transition: all 0.5s ease-in-out;
    min-width: 100%;
}
.section:hover{
    transform: scale(1.02);
}
.section.active{
    background-color: var(--dark-violet);
    width: calc(100vw - (2rem + 152px));
}
.section:not(.active){
    background-color: var(--background);
    cursor: pointer;
}
.section.active p, .section.active h3, .section.active i{
    color: var(--white);
}
div.ref-section{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
}
div.ref-section .pathology{
    font-size: 1.2rem;
    margin: 10px 0;
    padding: 0;
}
.ref-text p{
    white-space: nowrap;
}
.ref-number{
    font-size: 1.25rem;
    margin: 10px ;
    display: flex;
    justify-content: center;
}
div.info-section{
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column-reverse;
}
div.image{
    border-radius: 1rem;
    overflow: hidden;
    min-width: calc(45%);
}
.image img{
    object-fit: cover;
    height: 150px;
    width: 100%;
}
div.navigation{
    padding: 1rem 2rem 1.5rem;
    display: flex;
    justify-content: space-between;
}
div.navigation a{
    text-decoration: none;
}
p.before, p.next{
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
p.before:hover, p.next:hover{
    transform: scale(1.06);
}
p.read{
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
p.read:hover{
    transform: scale(1.01);
}
h3.title{
    font-size: 2rem;
}
.text p{
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
    margin: 0;
}
.text{
    padding-right: 0rem;
    padding-top: 1rem;
}
div.section.active .ref-information{
    display: flex;
    flex-direction: column;
    width: 100%;
}
div.section:not(.active) .ref-information{
    display: none;
}
.click-area{
    padding: 1rem 0;
}
.click-area p{
    margin: 0;
}
.click-area .before{
    text-align: left;
}
.click-area .next{
    text-align: right;
}
.before i, .next i{
    display: inline-block;
    transform: rotate(90deg);
}
.no-decoration{
    text-decoration: none;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .section{
        display: flex;
        min-height: 420px;
        min-width: min-content;
    }
    div.ref-section{
        flex-direction: column;
        padding: 1rem 0.5rem 1.5rem 0.5rem;
    }
    div.ref-section .pathology{
        transform: rotate(270deg);
    }
    .ref-text{
        width: 60px;
    }
    div.info-section{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 3rem 2rem 2rem;
    }
    .text{
        padding-right: 2.5rem;
    }
    .before i, .next i{
        display: inline-block;
        transform: rotate(0deg);
    }
    .image img{
        height: 250px;
    }
    .click-area .before{
        padding-right: 3rem;
    }
    .click-area .next{
        padding-left: 3rem;
    }
}
</style>