<template>
    <section>
        <SectionName text="Nuestro equipo" class="d-flex justify-content-center" />
        <TitleSecodary class="text-center mt-4 mx-auto col-lg-10" ppalText="Trabajamos duro" secondText="para revolucionar la medicina."/>
        <div class="accordion-container">
            <template v-for="sections in TeamInfo" :key="sections.id">
                <AccordionCards :ArrayInfo="sections.members" id="accordion-team" class="col-12 col-lg-6"/>
            </template>
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import AccordionCards from '@/components/AccordionCards.vue';
import TeamInfo from './team.js';

export default {
    name: 'OurTeam',
    components: {
        SectionName,
        TitleSecodary,
        AccordionCards,
    },
    data() {
        return {
            TeamInfo,
        }
    },
}
</script>

<style scoped>
.img-container{
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 444px;
}
.img-container img{
    height: 444px;
    width: 100%;
    object-fit: cover;
}
.image img{
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
.accordion-container{
    display: flex;
    flex-direction: column;
    gap: 0 2rem;
    padding-top: 3rem;
}
@media (min-width:992px) {
    .accordion-container{
        max-width: calc(100% - 2rem);
        flex-direction: row;
    }
}
</style>